import React, { useState } from 'react';
import { Button } from 'antd';

const content = [
    {
      id:0,
      tab: '선사시대∼고려시대',
      name: '1) 선사시대∼고려시대 이전',
      paragraph1: '지금까지 성북구 지역에서 선사시대 유물 또는 유적이 발굴된 적은 없다. 다만 성북구 지역은 북한산과 북악산의 산세를 서북쪽에 두고 두 산의 계곡에서 흘러 나오는 정릉천과 성북천을 끼고 있는 전형적인 배산임수 형태의 생활터전이다. 이러한 자연환경을 바탕으로 사람들이 정착하여 생활했을 것이라 추측해볼 수 있다.',
      paragraph2: '고구려, 백제, 신라로 잘 알려진 삼국은 한강 유역을 둘러싸고 잇따른 공방전을 펼쳤는데, 이 시기 성북구 지역도 이러한 영향을 받아 지속적으로 지배 세력의 교체를 겪었을 것이라 짐작된다. 이후 신라가 한반도를 통일하자 성북구 지역을 포함한 서울 지역은 한산주(漢山州)에 편입되었다. 이후 경덕왕은 한산주를 한주라 개칭하고 이 지역을 한양군(漢陽郡)이라 하였기에, 성북구 지역은 한주의 한양군에 속하게 되었다. ',
      paragraph3: '1967년 성북구와 인접한 지금의 강북구 삼양동에서 7세기 전반 신라의 것으로 추정되는 ‘서울 삼양동 금동관음보살입상’ (국보 제127호)이 출토되었는데, 이는 성북구 지역을 포함한 서울 지역이 신라 영토였음을 보여주는 유물이라 할 수 있다.'
    },
  {
        id:1,
        tab: '고려시대',
        name: '2) 고려시대',
        paragraph1: '918년 왕건이 고려를 건국한 이후 성북구 지역을 포함한 서울 지역은 정종(靖宗, 재위:1035∼1046) 때까지는 양주(楊州), 문종(文宗, 재위:1046~1083) 이후 충렬왕(忠烈王, 재위:1275∼1308) 때까지는 남경(南京), 충선왕(忠宣王, 재위:1308∼1313) 이후 고려 말까지는 한양부(漢陽府)로 불렸다.',
        paragraph2: '고려시대 수도 개경과 오늘날의 서울인 남경을 잇는 주요 교통로가 성북구 지역을 통과하였는데 지금의 미아사거리∼고려대 앞∼신설동∼동대문을 잇는 도로 일대로 보인다. 오늘날 이 지점들을 잇는 도로 주변으로는 보타사 마애불좌상, 보문사, 미타사 등 고려시대 불교 유적과 사찰들이 남아 있다. 이밖에 정릉동에도 고려 말에 창건된 경국사가 있어 고려시대 남경에 인접한 지역으로 생활권의 확대가 이루어졌던 성북구 지역의 옛 모습을 짐작할 수 있게 해준다.',
        paragraph3: '',
    },
      {
        id:2,
        tab: '조선시대',
        name: '3) 조선시대',
        paragraph1: '조선시대 성북구 일대는 한성부 성외(城外) 지역인 성저십리(城底十里)에 해당하였다. 조선 후기에는 숭신방(崇信坊)과 인창방(仁昌坊)에 속하였다. 성북구의 ‘성북’이라는 이름은 ‘도성의 북쪽’이라는 뜻을 담고 있다. 원래 성북은 조선시대에 한양의 둘레를 감싸고 있는 성곽의 북쪽에 있는 지역을 지칭하는 것이었는데 이것이 그 땅의 이름이 되어 지금까지 전해지고 있다. 1840년 조선시대 한양을 그린 ≪수선전도≫에서 ‘城北洞(성북동)’이라는 명칭을 찾아볼 수 있다. 예부터 북한산의 남쪽 기슭은 호랑이가 나올 정도로 산림이 우거진 곳이어서 조선의 왕이 친히 군대를 이끌고 호랑이 사냥을 나올 정도였다고 한다.',
        paragraph2: ' 지금의 성북지역은 당시 서울 동북쪽의 큰 관문이었다. 서울의 사소문 가운데 하나인 혜화문(=동소문)을 지나 강원도를 거쳐 함경도로 가는 동북방 간선도로가 성북구 지역을 관통했다. 따라서 일찍부터 여진과의 통로가 되었음은 물론 이 길을 통해 상인들이 통행하며 많은 물자들이 오갔다. 한양에서는 생활용품들이 주로 나갔으며, 함경도에서는 각종 어물들이 들어왔다고 한다. ',
        paragraph3: '조선 후기에는 성북동에 도성을 지키는 군대인 어영청(御營廳)의 북둔(北屯)을 설치하고, 여기에 소속된 군인들과 땅을 경작하며 살도록 민간인을 보냈다고 한다. 그러나 이 지역은 땅이 척박하고 농지도 부족하였기에 생베나 무명을 삶거나 빨아 볕에 말려 표백하는 ‘포백’과 콩을 삶아 메주를 쑤는 ‘훈조’를 할 수 있도록 포백훈조계를 설치하여 백성들의 생계와 부대의 재정을 충당하도록 하였다. 포백은 도성 안의 무명, 베, 모시 점포의 물건을 맡도록 했으며, 메주는 궁궐에 납품하도록 하였다.',
        paragraph4: ' 이처럼 백성들에게는 농사짓기 어려운 곳이었지만, 수려한 자연경관이 펼쳐지기 때문에 양반들은 이곳에 별장을 만들어 공부하는 곳으로 사용하였으며, 유명한 문인과 화가들은 거처를 마련하기도 했다. 특히 복숭아나무가 많아 행랑객들이 줄을 이었다고 전해진다.'
    },
      { id:3,
        tab: '일제강점기',
        name: '4) 일제강점기',
        paragraph1: '조선시대까지 성외 지역이었던 성북은 1936년 경성부 안에 포함되었다. 일제강점 초기 성북 지역은 고양군에 속해 있었지만 이후 경성부, 즉 지금의 서울시 권역으로 다시 편입된 것이다. 이 시기를 거치며 성북구 지역은 점차 큰 변화를 맞이한다.',
        paragraph2: '성북이 경성부에 속하지 않던 1914년부터 1929년 사이, 성북에는 공동묘지가 설치되었다. 당시는 교통사정이 좋지 않았기에 시가지에서 비교적 가까운 곳에 묘를 만들어야 했다. 또한 옛 풍습에 따라 상여를 꾸미고 직접 묘지까지 운반하는 장례 절차를 사람들이 지키면서 살고 있었다는 것도 공동묘지의 입지에 영향을 주었다. 이때 만들어진 미아리 공동묘지는 1950년대 중반에 모두 철거되었고 그 자리에는 철거민과 빈민들이 이주해 살게 되었다.',
        paragraph3: ' 1937년부터는 조선총독부가 돈암지구 토지구획정리사업을 실시해 이전보다 더 많은 사람들이 성북구 지역에 살게 되었다. 일제는 토지구획정리사업을 마쳤음에도 교외지역에 입주희망자가 없자 황무지로 남게 될 가능성을 염려했다. 그래서 시가지에 살면 가난해지고, 교외지역에 살면 행복해진다는 내용이 담긴 소설을 발표하는 등 대대적인 선전활동을 펼쳤으며, 직접 관이 개입하여 이 지역의 토지 매매를 진작시켰다. 그 효과 때문에 전통한옥도 아니고 서양식 주택도 아닌 ‘조선집’들이라 부른 ‘도시형 한옥’이 마치 지금 아파트가 들어서듯 만들어졌고, 여기에 많은 중류층 조선인들이 살게 되었다.',
        paragraph4: ' 돈암지구 일대가 주택지로 개발되면서 본래 이곳에 살던 토막민들은 정릉리와 정릉천 유역의 외곽 지역으로 밀려나 토막촌을 형성하였다. 토막촌은 각종 재난으로 가진 것을 잃은 사람들이 시내와 교외의 제방, 강바닥, 다리 밑 등에 초라한 살림의 움막을 지어놓고 모여 살던 동네를 이야기한다. 그런데 이들의 수가 너무 많아 처리를 고민하던 일제는 교외 지역이던 정릉리를 토막민 수용지로 설정하여 토막민들을 몰아넣었다. 토막민들은 1942년을 전후로 자취를 감추게 되는데 태평양전쟁으로 노동력이 부족해진 일제가 강제로 북해도 등지로 이주시켜 버린 것이 그 이유였다.',
        paragraph5: '성북구 지역에 사람들이 많이 살기 시작하면서 교육 기관도 속속 들어섰다. 1930년 중반 이후 보성전문학교, 경성고등상업학교, 일본인 대상 중학교들이 차례차례 설립되었다. 이때 세워진 학교들은 현재 성북구에 많은 대학교들이 위치하게 된 까닭이라 할 수 있다. 시내버스와 전차도 운행하기 시작하였고, 1941년에는 동소문 밖 미아리고개 근방 돈암동에 전차 종점이 설치되었다.'
    },
      {
        id:4,
        tab: '광복 이후',
        name: '5) 광복 이후',
        paragraph1: '성북구는 광복 이후인 1949년에 비로소 지금의 이름을 가지게 되었다. 그때 동대문구에 속했던 돈암동과 안암동, 종로구에 속했던 성북동, 경기도에 속했던 석관리·장위리·우이리·수유리·상월곡리·하월곡리·미아리·정릉리가 성북구 동네가 되었다. 이후 인구가 늘어나자 1973년 미아리고개 북쪽의 동네들 일부가 도봉구로, 1975년 석관동·종암동·안암동의 일부가 동대문구로, 정릉동·하월곡동의 일부가 도봉구로 편성되었다. 반대로 동대문구의 보문동은 성북구로 소속이 바뀌었다.',
        paragraph2: '광복 이후 성북구는 그 어느 때보다도 많은 변화를 겪게 된다. 전쟁 직후인 1953년부터 성북구의 인구는 서울의 다른 지역보다 훨씬 많이 증가하였다. 인구 증가의 이유에는 여러 가지 요인들이 있었다. 일단 전쟁 때 몰려든 피난민들이 파괴된 마을 주변에 움집을 지어 살았는데, 전쟁이 끝나고 이들이 집을 지어 살도록 한 뒤 새롭게 인구를 집계한 것이다. 또한 사회가 안정되고 서울에 일자리가 많아지자 지방으로부터 워낙 많은 사람들이 이주해 온 것도 인구증가의 원인이었다. 성북구는 당시만 해도 서울의 변두리였기 때문에 땅값이 싸서 많은 사람들이 몰렸다. 일제강점기에 여러 학교와 대학교들이 자리 잡은 것 역시 인구증가의 큰 요인이었다.',
        paragraph3: ' 사람들이 많이 살던 만큼 주거지도 늘어나게 되었다. 성북구에는 돌산을 뜻하는 한자 ‘암(岩)’이 들어가는 동네가 많아 주거지를 만들기 위해서는 돌들을 깎아내야 했다. 시인 김광섭의 <성북동 비둘기>는 이런 성북구의 모습을 잘 보여주고 있다. 일제강점기에 지어졌던 많은 도시형 한옥들이 헐리고 그곳에는 벽돌로 된 현대식 공동주택이 들어서게 되었다.',
    }
]
const useTabs = (initialTabs, allTabs) => {
    const [contentIndex, setContentIndex] = useState(initialTabs);
    return {
      contentItem: allTabs[contentIndex],
      contentChange: setContentIndex
    };
  };


function Sbhistory(){
    const { contentItem, contentChange } = useTabs(0, content);
    return(
        <div id='Archive' className='static container titleLayout'>
                <div className='Maintitle'>
                    <div>
                        <h2>성북구 시대별 역사</h2>
                        <p>선사시대~고려시대 이전 - 고려시대 - 조선시대 - 일제강점기 - 광복 이후</p>
                    </div>
                </div>
            {/*  content */}
            <div className=' contentsWrapper'>
            <p className='Subtitle'>찾으시는 시대를 선택해주세요 </p> <span> ( 하단에 설명이 표시됩니다 )</span>
            <div className='BtnWrapper'>
                {content.map((section, index) => (
                    <Button
                    type="button"
                    key={index}
                    onClick={() => contentChange(index)}
                    >
                    {section.tab}
                    </Button>
                ))}
            </div>

            <div className='ContentWrapper'>
            <h3 className='ContentTitle'>{contentItem.name}</h3>
            <p className=' contents'>{contentItem.paragraph1}</p>
            <p className=' contents'>{contentItem.paragraph2}</p>
            <p className=' contents'>{contentItem.paragraph3}</p>
            <p className=' contents'>{contentItem.paragraph4}</p>
            <p className=' contents'>{contentItem.paragraph5}</p>
            </div>
            </div>
        </div>
    )
}

export default Sbhistory;
