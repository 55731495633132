import React, { useState, useEffect } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Link, Route, Router, Switch, useRouteMatch, useParams } from "react-router-dom";
import MyInfo from './MyInfo';
import MyAction from './MyAction';
import MyInquiry from './MyInquiry';

const MyPageList = (props) => {
    const { userObj } = props;
    let { path, url } = useRouteMatch();
    let { params } = useParams()

    return(
        <>
        <div className='mycontainer'>
                <h1 style={{ fontWeight:"bold"}}>안녕하세요, { userObj ? (userObj.userNameReal || userObj.displayName || '회원') : '회원' } 님.</h1>
                <ul className='mypageNav mypageBox'>
                    <li>
                        <Link to={`${url}/info`}>
                            <RightOutlined />
                            <h3>기본정보</h3>
                            <p>이름, 연락처 등 기본 정보를 확인 · 수정할 수 있습니다</p>
                        </Link>
                    </li>
                    <li>
                        <Link to={`${url}/course`}>
                            <RightOutlined />
                            <h3>수강신청</h3>
                            <p>문화 강좌 수강을 위한 신청 · 수강 · 취소 내역을 확인할 수 있습니다</p>
                        </Link>
                    </li>
                    <li>
                        <Link to={`${url}/inquiry`}>
                            <RightOutlined />
                            <h3>1:1문의</h3>
                            <p>남겨주신 문의에 대한 답변 · 진행상황을 확인할 수 있습니다</p>
                        </Link>
                    </li>
                </ul>
        </div>
    </>
    )
}

export default MyPageList;
