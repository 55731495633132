/*global kakao*/ 
import React from "react";
import goglemap from "../../../assets/img/googlemap.svg";
import kakaomap from "../../../assets/img/kakaomap.svg";
import navermap from "../../../assets/img/navermap.svg";
import bus from "../../../assets/img/bus-2-line.svg";
import subway from "../../../assets/img/subway-line.svg";
import KaKaoMap from "./KakaoMap";

function Map() {
    return (
        <div id='maps' className="static container">
            <div className='RightBox'>
                <div className="kakaomap">
                     <KaKaoMap className='Map'/>
                </div>
            </div>
            <div className='LeftBox'>
                <h2 className='Title'><strong>오시는길</strong></h2>
                <h3><strong className="font-1_25">ADDRESS</strong></h3>
                <span>02834 <br/>서울시 성북구 성북로4길 13-8 (동소문동1가 1번지)</span>
                <p style={{paddingTop:'1em'}}><strong>TEL.  02 765 1611</strong><br/><strong>FAX.  02 765 1619</strong></p>
                <div>
                    <a className='Icon' target='_blank' href='https://goo.gl/maps/Pn9Nk24qs22JnAai6' rel="noreferrer noopener"><img className='Iconimg' src={goglemap} alt="구글지도"/>구글맵 길찾기</a>
                    <a className='Icon' target='_blank' href='http://kko.to/zR2v9uGYB' rel="noreferrer noopener"><img className='Iconimg' src={kakaomap} alt="카카오지도"/>카카오맵 길찾기</a>
                    <a className='Icon' target='_blank' href='http://naver.me/F5bCWHaM' rel="noreferrer noopener"><img className='Iconimg' src={navermap} alt="네이버지도"/>네이버맵 길찾기</a>
                </div>
                <div className='ContentsWrapper'>
                    <div className='Btni clear'><img src={subway} alt='지하철'/></div>
                    <div>
                       <p>지하철 이용시</p> 
                        <span><strong>4호선</strong> 한성대입구(삼선교)역 하차, <br/><strong>6번 출구</strong> 한신 아파트 방향 도보 3분 거리</span>
                    </div>
                </div>
                <div className='ContentsWrapper'>
                <div className='Btni clear'><img src={bus} alt='버스'/></div>
                <div>
                    <p>버스 이용시</p>
                    <span>지선버스 <strong>1111번</strong> 삼선교(성북문화원),<br/>또는 마을버스 <strong>01번</strong> 삼선교(성북문화원) 하차</span>
                </div>
                </div>
            </div>
            
        </div>

    )
}
export default Map;