import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/styles/MainSlide.css";
import axios from "axios";
import {defaultApiAddress} from "../../services/api.helpers";

const _type = 'banner';     // 배너 타입
const _cate = 'main';       // 이달의 책 배너(book)    <-->    메인 배너(main)

const MainSlide = () => {
    const location = useLocation();

    const [ next, setNext ] = useState();
    const [ previous, setPrevious ] = useState();
    const [ updateCount, setUpdateCount ] = useState(0);
    const [ slideIndex, setSlideIndex ] = useState(1);
    const [ mobile, setMobile ] = useState(false);
    const [ width, setWidth ] = useState(window.innerWidth);

  const slideImg = [
    {
      index:0,
      link:"#",
      url:"/image/mainbanner-loading-blank.jpg",
      urlM:"/image/mainbanner-loading-blank-m.jpg",
      alt:"slide 01"
    },
    // {
    //   index:1,
    //   link:"#",
    //   imgUrl:"/image/mainslide02.png",
    //   alt:"slide 02"
    // },
    // {
    //   index:2,
    //   link:"#",
    //   imgUrl:"/image/mainslide01.png",
    //   alt:"slide 03"
    // },
    // {
    //   index:3,
    //   link:"#",
    //   imgUrl:"/image/mainslide02.png",
    //   alt:"slide 04"
    // },
  ]

  const [slideData , setSlideData] = useState(slideImg);
  const [bannerLoaded, setBannerLoaded] = useState(false);

  const slider = useRef();
  // console.log(slider.current);
  const slideMap = (slideNum) => {
    if(Array.isArray(slideNum)){
      return (
        slideNum.map((each, index) => (
          <div key={index}>
                  <a href={(!each.link) ? '#' : each.link} key={each.index}>
                      { mobile
                          ? <img src={!bannerLoaded ? slideImg[0].urlM : (!!each.url ? defaultApiAddress+each.urlM : slideImg[0].urlM)} alt={'메인배너'+(each.index+1)} style={imgStyleM}  />
                          : <img src={!bannerLoaded ? slideImg[0].url : (!!each.url ? defaultApiAddress+each.url : slideImg[0].url)} alt={'메인배너'+(each.index+1)} style={imgStyle}  />
                      }
                  </a>
                </div>
              ))
              )
            }
  }

    const slideLength = slideData.length;
    const getDataAPI = async () => {
        axios.get(`${defaultApiAddress}/boards?type=${_type}&cate=${_cate}`)        // 배너 타입, 메인 배너
            .then(res => {
                setSlideData(res.data.results[0].info);
                setBannerLoaded(true);      // 배너 정보가 정상적으로 로드됬음을 의미
            }).catch((error)=>{
            if (error.response) {
                if (error.response.data.errorCode === 'NOT_EXIST_TYPE_CATEGORY') {
                    //배너용 자료가 없을 때.
                }
            }
        });
    }

    useEffect(() => {
        getDataAPI();
        return () => {};
    }, [location.pathname]);

    useEffect(() => {
        // console.log('width-',width);
        if (width < 480) {
            setMobile(true);
        } else {
            setMobile(false);
        }
        return () => {};
    }, [width]);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const onNext = () => {
      setNext(slider.current.slickNext());
    }

    const onPrevious = () => {
      setPrevious(slider.current.slickPrev());
    }

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      swipe: true,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      afterChange: () =>
        setUpdateCount( updateCount + 1 ),
      beforeChange: (current, next) => setSlideIndex(next + 1)
    };

    const imgStyle = {
      margin: "0 auto",
      width:"100%",
      maxHeight:"50vh",
      maxWidth:"100%"
    }

    const imgStyleM = {
        margin: "0 auto",
        width:"100%",
        // maxHeight:"50vh",
        maxWidth:"100%"
    }

    return (
      <div className="poster">
          <Slider ref={c => {slider.current = c}} {...settings} >{slideMap(slideData)}</Slider>
        <div className="arrowbtWrap">
        <button className="button arrowBt" onClick={onPrevious}>
          &#60;
        </button>
        <span style={{ color: '#828282',fontSize:"1.2rem", lineHeight:"1.5rem",margin:"0 5px", }}>
          <strong>{slideIndex}</strong> / {slideLength}
        </span>
        <button className="button arrowBt" onClick={onNext}>
          &#62;
        </button>
      </div>
      </div>
    );
  }

  export default MainSlide;
