import React, { useState, useEffect } from "react";
import {Link, useHistory, useParams, useLocation, useRouteMatch} from 'react-router-dom'
import axios from "axios";
import moment from 'moment';
// import { useRecoilValue } from "recoil";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import { Button, Typography, Row, Col, Modal, Form, Alert, Input} from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons';
import InquiryComment from './InquiryComment';

const { Title, Text } = Typography;
const { TextArea } = Input;
export default function MyInquiryDetail(props) {
    const { userObj, inquiryId } = props;
    const history = useHistory();
    const location = useLocation();
    let { params } = useParams();
    let { path } = useRouteMatch();
    // console.log(params);
    // console.log(path);
    const [showMessageErr, setShowMessageErr] = useState(false);
    const [messageErr, setMessageErr] = useState(null);
    const [localToken, setLocalToken] = useState(null);

    const errorAlert = ()=>{
        setTimeout(()=>{
            setShowMessageErr(false);
        },4000);
        return (
            <Alert
                className="alert-result-true"
                message="오류"
                description={messageErr}
                type="error"
                showIcon
                closable={true}
            />
        )
    }

    const [ detailData, setDetailData ] = useState({
        id: null,
        status: false,
        title: null,
        type: null,
        description: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        createdUserId: null,
        updatedUserId: null,
        answerCount:null,
    });
    // const replyStatus = useRecoilValue(replyState);

    // recoil State (리스트에서 클릭한 게시판 id)
    // const listId = useRecoilValue(idState);
    const [ status, setStatus ] = useState(false);
    // date표기법 포맷 state
    const [ formatDate, setFormatDate ] = useState('');

    const getDataAPI = async (_idToken) => {
        setLocalToken(_idToken);    // 함수에 포함하기 어려운 경우
        await axios.get(`${defaultApiAddress}/inquiries/${inquiryId}`, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(res => {
                setDetailData(res.data);
                console.log(res.data);
                setFormatDate(res.data.createdAt);
                if(res?.data?.answerCount >= 1){
                    setStatus(true);
                }
            }).catch((error)=>{
                if (!_idToken) {
                    // 모달 사용으로 인한 비활성화
                    // setMessageErr('로그인이 필요합니다!');
                    // setShowMessageErr(true);
                    console.log('로그인이 필요합니다!');
                } else {
                    if (error.response) {
                        setMessageErr(error.response.data.message);
                        setShowMessageErr(true);
                    }
                }
            });
    }

    const getNewComment = state => {
        if(state === true){
            setStatus(true)
        }else{
            setStatus(false)
        }
    }

    useEffect(() => {
        if (userObj != null) {
            refreshToken().then((_idToken)=>{getDataAPI(_idToken)});
        } else if (userObj == null) {
            // console.log('로그인 못함');
            setMessageErr('로그인 해주시기 바랍니다.');
            setShowMessageErr(true);
        }
        return () => {};
    }, [location.pathname]);

    console.log(detailData?.answerCount);

    useEffect(() => {
        if(detailData?.answerCount >= 1){
            setStatus(true);
        }else if(detailData?.answerCount === 0){
            setStatus(false);
        }
    }, [status])

    const [isModalVisible, setIsModalVisible] = useState(false);

    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    // const editInquiry = () => {
    //     return history.push({
    //         pathname:`/mypage//inquiryDetail/${inquiryId}/write`,
    //     });
    // }

    const inquiryDeleteConfirm = async(event) => {
        await deleteInquiry(event.target.value);

        refreshToken().then((_idToken)=>{getDataAPI(_idToken)});
        // message.success('글이 삭제되었습니다!');
        return history.push({
            pathname:'/mypage/inquiry',
        });
    };

    const deleteInquiry = () => {
        return axios.delete(`${defaultApiAddress}/inquiries/${inquiryId}`, {headers: {
                "Authorization" : localToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(() => {
                console.log('inquiry deleted');
            });
    };

    const inquiryDeleteCancled = () => {
        setIsModalVisible(false);
    };

    const onHandleBack = () => {
        history.push(`/mypage/inquiry`);
    };

    return(
        <div className="mycontainer">
            { detailData !== null ?
                <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
                    <Row className="detailMainInfoWrap">
                        <Col span={2}>
                            <Button onClick={onHandleBack} type="text" className='history'>
                                <ArrowLeftOutlined
                                    style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                                />
                                <span style={{color:"#828282", fontWeight:600, fontSize:14}}>목록</span>
                            </Button>
                        </Col>
                        {/*<Col span={2}>*/}
                        {/*    /!*<Button onClick={editInquiry} danger type="text">수정하기</Button>*!/*/}
                        {/*    <Button onClick={showModal} danger type="text" className='delete'>삭제하기</Button>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row className="grid-title-wrapper" style={{padding:'0px 20px'}}>
                    <Row span={24} className="border-grid" justify="start">
                        <Col span={4} className="border-grid-title">
                            <span>제목</span>
                        </Col>
                        <Col span={20} className="border-grid-content">
                            <span>{ detailData?.title?.slice(0, 100) }</span>
                        </Col>
                    </Row>
                    <Row span={24} className="border-grid-row" justify="start">
                        <Col span={4} className="border-grid-title">
                            <span>이름</span>
                        </Col>
                        <Col span={8} className="border-grid-content">
                            <span>{ detailData?.createdBy?.userNameReal || detailData?.createdBy?.userName }</span>
                        </Col>
                        <Col span={4} className="border-grid-title" style={{borderLeft: '1px solid #e0e0e0'}}>
                            <span>작성일</span>
                        </Col>
                        <Col span={5} className="border-grid-content">
                            <span>{ moment(formatDate).format('YYYY/MM/DD') }</span>
                        </Col>
                    </Row>
                    <Row span={24} className="border-grid-row" justify="start">
                        <Col span={4} className="border-grid-title">
                            <span>문의<br className='onlyMobile'/>유형</span>
                        </Col>
                        <Col span={8} className="border-grid-content">
                            <span>{ detailData.type }</span>
                        </Col>
                        <Col span={4} className="border-grid-title" style={{borderLeft: '1px solid #e0e0e0'}}>
                            <span>답변<br className='onlyMobile'/>상태</span>
                        </Col>
                        <Col span={5} className="border-grid-content">
                            <span>{ status === true ? '답변 완료' : '미완료'}</span>
                        </Col>
                    </Row>
                    <Row span={24} className="border-grid" justify="start" style={{paddingLeft:0, paddingRight:0}}>
                        <Col span={24} className="border-grid-title" style={{borderBottom:'1px solid #e0e0e0', borderLeft:'none', borderRight:'none'}}>
                            <span>문의내용</span>
                        </Col>
                        <Col span={24} className="border-grid-content" style={{padding:'20px 30px', minHeight:400,}}>
                            <TextArea className="textAreaView" value={detailData?.description} disabled></TextArea>
                        </Col>
                    </Row>
                    <Row className="comment-row">
                        {/* <Col span={24} className="border-grid-title" style={{borderLeft:'none', borderRight:'none'}}>
                             <span>문의답변</span>
                        </Col> */}
                        <InquiryComment
                            inquiryId={inquiryId}
                            userObj={userObj}
                            detailData={detailData}
                            history={history}
                            status={status}
                            getNewComment={getNewComment}
                        />
                        
                    </Row>
                    </Row>
                    <Form.Item>
                        <Modal title="주의" visible={isModalVisible} onOk={inquiryDeleteConfirm} onCancel={inquiryDeleteCancled}>
                            <p>게시글이 삭제됩니다.</p>
                            <p>삭제하시겠습니까?</p>
                        </Modal>
                    </Form.Item>
                </Row>

                : null }
            {showMessageErr ? errorAlert() : null}
        </div>
    );
}
