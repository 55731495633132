import React ,{ useState, useEffect }from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import axios from "axios";
import GalleryList from "../gallery/GalleryList";
import TextDetail from "../gallery/TextDetail";
import '../../assets/styles/Layout.css';
import '../../assets/styles/Board.css';

// Gallery Type Board

function Hfigure(props){
    const { init, userObj } = props;
    const history = useHistory();
    const type = 'gallery';
    const cate = 'hfigure';
    const group = 'seongbuk';

    return (
        <>
        <Switch>
            <Route path="/seongbuk/hfigure/detail/:bbsId" exact>
                <TextDetail cate={cate} type={type} group={group} />
            </Route>
            <Route path="/seongbuk/hfigure" exact>
                <GalleryList type={type} cate={cate} group={group} userObj={userObj} init={init} />
            </Route>
        </Switch>
        </>
        );

};

export default Hfigure;
