import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Card, Row, Col, Skeleton } from 'antd';
import {Link, useHistory, useLocation} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {defaultApiAddress} from "../../services/api.helpers";
import axios from "axios";
import moment from 'moment';

const _type = 'gallery';
const _cate = 'gallery';
const _number = 8; // 메인에서 끌어올 갤러리 게시글 수

const GallerySlide = () => {
    const location = useLocation();
    const history = useHistory();

    const slideImg = [
        {
            "dummy": true,
            "id": 0,
            "title": "",
            "mediaUrl": "https://dummyimage.com/275x183/ebebeb/ebebeb.jpg",
            "createdAt": moment("2021-01-01T00:00:00.000Z").format('YYYY/MM/DD'),
        },
        {
            "dummy": true,
            "id": 1,
            "title": "",
            "mediaUrl": "https://dummyimage.com/275x183/ebebeb/ebebeb.jpg",
            "createdAt": moment("2021-01-01T00:00:00.000Z").format('YYYY/MM/DD'),
        },
        {
            "dummy": true,
            "id": 2,
            "title": "",
            "mediaUrl": "https://dummyimage.com/275x183/ebebeb/ebebeb.jpg",
            "createdAt": moment("2021-01-01T00:00:00.000Z").format('YYYY/MM/DD'),
        },
        {
            "dummy": true,
            "id": 3,
            "title": "",
            "mediaUrl": "https://dummyimage.com/275x183/ebebeb/ebebeb.jpg",
            "createdAt": moment("2021-01-01T00:00:00.000Z").format('YYYY/MM/DD'),
        }
    ];

    const [ next, setNext ] = useState();
    const [ previous, setPrevious ] = useState();
    const [ updateCount, setUpdateCount ] = useState(0);
    const [ slideIndex, setSlideIndex ] = useState(1);

    const [slideData , setSlideData] = useState(slideImg);
    const [bannerLoaded, setBannerLoaded] = useState(false);

    const slider = useRef();
    // console.log(slider.current);
    const slideMap = (slideNum) => {
        if(Array.isArray(slideNum)){
            return (
                slideNum.map((each, index) => (
                    <div key={index}>
                        <Card
                            style={{ marginLeft:"1rem",overflow:"hidden"}}
                            cover={<img alt={"gallery"+index} src={!each.dummy ? defaultApiAddress+each.mediaUrl : each.mediaUrl}/>} /*style={{height:"250px"}} */
                        >
                            <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}
                                onClick={(e)=>{if (!each.dummy) {history.push('/event/gallery/detail/'+each.id)}}}
                            >
                                <li style={{marginBottom:5}}>
                                    {!each.dummy
                                        ? <span style={{fontSize:12, color:'#828282'}}>{each.type}</span>
                                        : <Skeleton.Input size="small" style={{width:'100px'}} />
                                    }
                                </li>
                                <li className="gallery-card-title">
                                    {!each.dummy
                                        ? <span style={{}}>{each.title}</span>
                                        : <Skeleton.Input size="small" style={{width:'100px'}} />
                                    }
                                </li>
                                <li style={{textAlign:"right"}}>
                                    {!each.dummy
                                        ? <span style={{fontSize:12, color:'#828282'}}>{moment(each.createdAt).format('YYYY/MM/DD')}</span>
                                        : <Skeleton.Input size="small" style={{width:'100px'}} />
                                    }
                                </li>
                            </ul>
                        </Card>
                    </div>
                ))
            )
        }
    }

    const getDataAPI = async () => {
        axios.get(`${defaultApiAddress}/boards?type=${_type.toUpperCase()}&cate=${_cate.toUpperCase()}&page=0&rpp=${_number}`)        // 배너 타입, 메인 배너
            .then(res => {
                if (res.data.totalCount < 4) {      //4개보다 적을때, 그대로 노출시 레이아웃이 깨짐
                    let _slides = [...res.data.results];
                    for (let i = 0; i < (4-res.data?.totalCount); i++) {
                        _slides.push(slideImg[i]);
                    }
                    setSlideData(_slides);
                } else {
                    setSlideData(res.data.results);
                }
                setBannerLoaded(true);
            }).catch((error)=>{
            if (error.response) {
                if (error.response.data.errorCode === 'NOT_EXIST_TYPE_CATEGORY') {
                    //배너용 자료가 없을 때.
                }
            }
        });
    }

    useEffect(() => {
        getDataAPI();
        return () => {};
    }, [location.pathname]);

    var gSlidesettings = {
        dots: false,
        infinite: true,
        speed: 500,
        height:400,
        slidesToShow: 4,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 786,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        ]
    };

    return <>
        <div>
            <Row className="iconList" justify="flex-start" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={22} md={22} lg={5}>
                    <ul className="iconListTitle">
                        <li className="listText">성북</li>
                        <li className="textBold" >문화행사</li>
                        <li className="listText">갤러리</li>
                        <li className="listMore">
                            <Link to="/event/gallery">더보기+</Link>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Slider {...gSlidesettings}>
                {slideMap(slideData)}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem",overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem",overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem",overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem",overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem",overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem",overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem",overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{ marginLeft:"1rem", maxWidth:320, overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Card*/}
                {/*        style={{maxWidth:320, overflow:"hidden"}}*/}
                {/*        cover={<img alt="gallery01" src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" style={{height:"250px"}} />}*/}
                {/*    >*/}
                {/*        <ul style={{ width:"100%", height:"100%", padding:0, margin:"0 auto", textAlign:"left"}}>*/}
                {/*            <li style={{marginBottom:5}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>성북문화유산야행</span>*/}
                {/*            </li>*/}
                {/*            <li style={{marginBottom:20}}>*/}
                {/*                <span style={{fontSize:"1.4rem", color:'#5A5A5A', fontWeight:"bold"}}>Gallery Image 01</span>*/}
                {/*            </li>*/}
                {/*            <li style={{textAlign:"right"}}>*/}
                {/*                <span style={{fontSize:12, color:'#828282'}}>2021/02/15</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}

                {/*    </Card>*/}
                {/*</div>*/}

            </Slider>
        </div>
    </>

}
export default GallerySlide;
