import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import WithoutSkeletonA from './WithoutSkeletonA';
import WithSkeleton from '../boardBasic/WithSkeleton';
import {defaultApiAddress} from "../../services/api.helpers";

export default function AttachDetail(props) {
    const { userObj, history, cate } = props;
    const { bbsId } = useParams();
    const [ detailData, setDetailData ] = useState(null)
    const [loading, setLoading] = useState(true);
    // date 포맷 state

    const getDetailApi = async() => {
        await axios.get(`${defaultApiAddress}/boards/${bbsId}?cate=${cate}`)
            .then(res => {
                setDetailData(res.data);
                return setLoading(false)
            })
        return () => {};
    }

    useEffect(() => {
        if(detailData === null) {
            getDetailApi();
        }else{
            // console.log('useEffect 콘솔:'+detailData);
        }
        return () => {};
    },[bbsId]);

    return(
        <>
            { loading ?
                <WithSkeleton detailData={detailData} loading={loading} /> :
                <WithoutSkeletonA
                    history={history}
                    detailData={detailData}
                    loading={loading}
                    userObj={userObj}
                    cate={cate} />
            }
        </>
    );
}
