import React, {useState, useEffect} from 'react';
import { NavLink, Link, useHistory } from "react-router-dom";
import { Card, Col, Row } from 'antd';

function SbcBoard() {
    return(
        <>
            <div className="board-card-wrapper">
                <Row >
                    <Col span={22} sm={22} md={22} lg={6} className="sbcboradTitle">
                        <div>성북</div>
                        <div>문화</div>
                    </Col>
                    <Col span={22} xs={22} sm={8} lg={6}>
                        <Link to="/event/e-culrture">
                        <Card
                            cover={<img alt="" src="/image/sbcboard01.jpg"/>}>
                            문화행사
                            <span>더보기+</span>
                        </Card>
                        </Link>
                    </Col>
                    <Col span={22} xs={22} sm={8} lg={6}>
                        <Link to="/event/e-academic">
                        <Card
                            cover={<img alt="" src="/image/sbcboard02.jpg"/>}>
                            학술행사
                            <span>더보기+</span>
                        </Card>
                        </Link>
                    </Col>
                    <Col span={22} xs={22} sm={8} lg={6}>
                        <Link to="/seongbuk/sb-history">
                        <Card
                            cover={<img alt="" src="/image/sbcboard03.jpg"/>}>
                            성북학
                            <span>더보기+</span>
                        </Card>
                        </Link>
                    </Col>
                </Row>
            </div>
        </>
    )
} 

export default SbcBoard;
