import React, { createElement, useEffect, useState } from 'react';
import { Comment, Tooltip, Avatar, Form, Button, List, Input, Col, Row, Modal, Empty } from 'antd';
import moment from 'moment';
import axios from "axios";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
// import { useRecoilState } from "recoil";
// import { replyState, replyId } from "../../recoil-state/inquiryState";

const { TextArea } = Input;
/*
const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item >
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting}
                    onClick={onSubmit}
                    type="primary">
                답변 작성
            </Button>
        </Form.Item>
    </>
);

const EditEditor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting}
                    onClick={onSubmit}
                    type="primary">
                답변 수정
            </Button>
        </Form.Item>
    </>
);*/

const InquiryComment = (props) => {
    const { userObj, inquiryId, history, getNewComment, status, detailData } = props;
    // const [ reply, setReply] = useRecoilState(replyState);
    // const [ replyidState, setreplyId ] = useRecoilState(replyId);
    const [ replyData, setreplyData ] = useState(null);
    // const [ edit, setEdit] = useState(false);
    // const [ state, setState ] = useState({
    //     comments: [],
    //     submitting: false,
    //     value: '',
    //     inquiryId: null,
    //     description:null,
    // });

    const getReplApi = async(_idToken) => {
        await axios.get(`${defaultApiAddress}/inquiries/${inquiryId}/answers`, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(res => {
                console.log(res?.data);
                setreplyData(res?.data);
                // setreplyId(res?.data?.id)
                // setReply(true);
            })
    }

    const [form] = Form.useForm();

    useEffect(()=>{
        if (userObj != null) {
            refreshToken().then((_idToken)=>{getReplApi(_idToken)});
        }
        console.log(detailData, inquiryId);
    },[detailData]);
/*
    useEffect(() => {
        console.log(replyData);
        if(status === true){
            if(edit === false){
                getReplApi();
            }
        }else return () => {};
    }, [status]);
*/

/*    useEffect(() => {
        console.log(replyData);
        if(!!userObj){
            getReplApi();
        }else return () => {};
    }, [userObj]);*/


/*    const handleSubmit = () => {
        if (!state.value) {
            return;
        }
        console.log(state.value)
        // setState({
        //     submitting: true,
        // });
    };*/

    /*const updateBoard = () => {
        // setEdit(true);
        // setState({...state,
        //     submitting: false,
        // })
        console.log(state)
        // setreplyData(null);
    };*/

    const handleChange = (event) => {
        // console.log(event.target.value)
        /*setState({...state,
            value:event.target.value,
        })*/
    }


    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    return(
        <Form
            name="replyform"
            form={form}
            style={{border: '1px solid #e0e0e0', padding:"24px"}}
            // onFinish={handleSubmit}
        >
            <Col span={24}>
                { replyData
                 ? <Comment
                        style={{ textAlign: 'center' }}
                        // actions={actions}
                        author={<span style={{fontSize:'1rem', fontWeight:'bold'}}>성북문화원</span>}
                        avatar={
                            <Avatar
                                src="/images/NoneTextLogo.png"
                                alt="성북문화원"
                            />
                        }
                        content={
                            <>
                                <p style={{textAlign: 'left' }}>
                                    {replyData?.description}
                                </p>
                            </>
                        }
                        datetime={
                            <Tooltip>
                                {/* a few days ago 멘트 삭제 */}
                                {/* <span style={{fontSize:'1rem', fontWeight:'bold'}}>{moment(replyData?.updatedAt).fromNow()},ㅤ</span> */}
                                <span style={{fontSize:'0.9rem'}}>{ moment().format('YYYY/MM/DD HH:mm:ss')}</span>
                            </Tooltip>
                        }
                    />
                    : <div>
                        답변없음
                    </div>}
                {/*{ replyData ?
                    edit ?
                        <Comment
                            avatar={
                                <Avatar
                                    src="/images/NoneTextLogo.png"
                                    alt="성북문화원"
                                />
                            }
                            content={
                                <EditEditor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={state.submitting}
                                />
                            }
                        />
                        :
                        <Comment
                            style={{ textAlign: 'center' }}
                            actions={actions}
                            author={<span style={{fontSize:'1rem', fontWeight:'bold'}}>성북문화원</span>}
                            avatar={
                                <Avatar
                                    src="/images/NoneTextLogo.png"
                                    alt="성북문화원"
                                />
                            }
                            content={
                                <>
                                    <p style={{textAlign: 'left' }}>
                                        {replyData?.description}
                                    </p>
                                </>
                            }
                            datetime={
                                <Tooltip>
                                    <span style={{fontSize:'1rem', fontWeight:'bold'}}>{moment(replyData?.updatedAt).fromNow()},ㅤ</span>
                                    <span style={{fontSize:'0.9rem'}}>{ moment().format('YYYY/MM/DD HH:mm:ss')}</span>
                                </Tooltip>
                            }
                        />
                    :
                    <Comment
                        avatar={
                            <Avatar
                                src="/images/NoneTextLogo.png"
                                alt="성북문화원"
                            />
                        }
                        content={
                            <Editor
                                onSubmit={handleSubmit}
                                onChange={handleChange}
                                submitting={state.submitting}
                                // value={state.value}
                            />
                        }
                    />
                }
                Comment
                style={{ textAlign: 'center' }}
                actions={actions}
                author={<span style={{fontSize:'1rem', fontWeight:'bold'}}>성북문화원</span>}
                avatar={
                <Avatar
                    src="/images/NoneTextLogo.png"
                    alt="성북문화원"
                />
            }
                content={
                <>
                    <p style={{textAlign: 'left' }}>
                        {detailData?.description}
                    </p>
                </>
            }
                datetime={
                <Tooltip>
                    <span style={{fontSize:'1rem', fontWeight:'bold'}}>{moment(detailData?.updatedAt).fromNow()},ㅤ</span>
                    <span style={{fontSize:'0.9rem'}}>{ moment().format('YYYY/MM/DD HH:mm:ss')}</span>
                </Tooltip>
            }
                />
*/}
            </Col>
            <Form.Item>
                <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} >
                    <p>답변이 삭제됩니다.</p>
                    <p>삭제하시겠습니까?</p>
                </Modal>
            </Form.Item>
        </Form>
    );
}

export default InquiryComment;
