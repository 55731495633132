import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import "antd/dist/antd.css";
import boardData from "../../data/boardData.json";
import { MenuOutlined, CopyOutlined } from "@ant-design/icons";
import { Drawer, Button, Tabs } from "antd";
import FooterBottom from "../footer/FooterBottomWhite";
const { TabPane } = Tabs;


const MobileNavTab = () => {

  const location = useLocation();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location])


  return (
    <div id='Mobile-nav'>

      <Button onClick={showDrawer} className='movile-nav-openBtn'>
        <MenuOutlined/>
      </Button>
      <Drawer
        // title="Basic Drawer"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        destroyOnClose={false}
      >


        <div className='nav-wrapper'>
            <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="문화원소개" key="1" >
              <ul className="subListUl-m">
                <Link onClick={onClose} to="/sbcc/greetings" >
                  <li>인사말<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/sbcc/history">
                  <li>연혁<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/sbcc/organization-c">
                  <li>조직도<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/sbcc/business">
                  <li>주요사업<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/sbcc/executives">
                  <li>임원현황<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/sbcc/map">
                  <li>오시는길<CopyOutlined /></li>
                </Link>
              </ul>
              </TabPane>
              <TabPane tab="문화강좌" key="2">
              <ul className="subListUl-m">
                <Link onClick={onClose} to="/program/course-guide">
                  <li>수강안내<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/program/program-all">
                  <li>프로그램<CopyOutlined /></li>
                </Link>
                <ul>
                  <Link onClick={onClose} to="/program/list01">
                    <li>미술·서예<CopyOutlined /></li>
                  </Link>
                  <Link onClick={onClose} to="/program/list02">
                    <li>악기·음악<CopyOutlined /></li>
                  </Link>
                  <Link onClick={onClose} to="/program/list03">
                    <li>댄스·무용<CopyOutlined /></li>
                  </Link>
                  <Link onClick={onClose} to="/program/list04">
                    <li>생활교양<CopyOutlined /></li>
                  </Link>
                  <Link onClick={onClose} to="/program/list05">
                    <li>어린이<CopyOutlined /></li>
                  </Link>
                </ul>
              </ul>
              </TabPane>
              <TabPane tab="행사안내" key="3">
              <ul className="subListUl-m">
                <Link onClick={onClose} to="/event/e-culrture">
                  <li>문화행사<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/event/e-academic">
                  <li>학술행사<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/event/gallery">
                  <li>갤러리<CopyOutlined /></li>
                </Link>
              </ul>
              </TabPane>
              <TabPane tab="성북학" key="4">
              <ul className="subListUl-m">
                <Link onClick={onClose} to="/seongbuk/sb-history">
                  <li>역사<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/seongbuk/named">
                  <li>동명유래<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/seongbuk/cultural">
                  <li>문화유산<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/seongbuk/hfigure">
                  <li>인물<CopyOutlined /></li>
                </Link>
              </ul>
              </TabPane>
              <TabPane tab="커뮤니티" key="5">
              <ul className="subListUl-m">
                <Link onClick={onClose} to="/commu/notice">
                  <li>공지사항<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/commu/inquiry">
                  <li>문의 및 민원<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/commu/family-sites">
                  <li>관련사이트<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/commu/news">
                  <li>보도자료<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/commu/reference">
                  <li>자료실<CopyOutlined /></li>
                </Link>
                <Link onClick={onClose} to="/commu/public">
                  <li>정보공개<CopyOutlined /></li>
                </Link>
              </ul>
              </TabPane>
              <TabPane tab="성북마을아카이브" key="6">
              </TabPane>
            </Tabs>
            <a href="https://archive.sb.go.kr/isbcc/home/u/index.do" target='_blank' rel="noreferrer noopener" className="onclicklink">성북마을아카이브</a>
        </div>
        <FooterBottom/>
      </Drawer>

    </div>
  );
};

// const menu1Data = [인사말, 연혁, 조직도, 주요사업, 임원현황, 오시는길];
// const menu1 = menu1Data.map((menu) =>
// <>
//   <li>
//   {menu}<CopyOutlined /></li>
// </>
// );

// export const MobileNavData = () => {
//   return (
//     <>
//       <ul className="subListUl-m">
//         {boardData.map((data, key) => {
//           return (
//             <li key={key}>
//               <>
//               {data.sublist.title}
//               <CopyOutlined />
//               </>
//             </li>
//           );
//         })}
//       </ul>
//     </>
//   );
// };



function MobileNav() {
  const [ menuList, setMenuList ] = useState(boardData);
    return (
        <div id="Mobile-nav">
          <MobileNavTab />
      </div>
    )
}
export default MobileNav;
