import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Sbcc from "./Sbcc";
import Program from "./Program";
import Event from "./Event";
import SeongBuk from "./SeongBuk";
import Community from "./Community";

// boardChild 컴포넌트는 Board의 콘텐츠 영역 부분을 location value의 타이틀 값에 따라
// 일치하는 컴포넌트를 불러와 주는 역할. 21.03.17 Asha

const BoardChild = (props) => {
    const { groupValue, value, userObj, setUserObjFn, init } = props;

    const MatchComponent = () => {
         switch(groupValue) {
                    case "sbcc": return <Sbcc groupValue={groupValue} value={value} />; break;
                    case "program": return <Program groupValue={groupValue} userObj={userObj} setUserObjFn={setUserObjFn} value={value} init={init} />; break;
                    case "event": return <Event userObj={userObj} init={init} />; break;
                    case "seongbuk": return <SeongBuk userObj={userObj} init={init} />; break;
                    case "commu": return <Community userObj={userObj} init={init} />; break;
    }
}

    return (
        <>
        <MatchComponent />
        </>
    )
}

export default BoardChild;
