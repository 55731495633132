import React from "react";
import { Table } from 'antd';


const columns = [
  {
    title: '번호',
    dataIndex: 'key',
    width: '16%',
    align: 'center'
  },
  {
    title: '직위',
    dataIndex: 'spot',
    width: '20%',
  },
  {
    title: '성명',
    dataIndex: 'name',
    width: '20%',
  },
  {
    title: '근무처 및 직위',
    dataIndex: 'address',
  },
];

// TABLE1
const data1 = [
  {
    key: '1',
    spot: '1대원장',
    name: '故 구두회',
    address: '(주)예스코 회장'
  },
  {
    key: '2',
    spot: '2대원장(명예원장)',
    name: '천신일',
    address: '(주)세중그룹 회장'
  },
  {
    key: '3',
    spot: '3대원장(명예원장)',
    name: '조태권',
    address: '(주)광주요그룹 회장'
  },
];

// TABLE2
const data2 = [
  {
    key: '1',
    spot: '원장',
    name: '김영일',
    address: '(주)태명실업 회장'
  },
  {
    key: '2',
    spot: '부원장',
    name: '백병수',
    address: '자일자동차㈜ 대표이사'
  },
  {
    key: '3',
    spot: '부원장',
    name: '최종명',
    address: '최종명세무사사무소 대표'
  },
  {
    key: '4',
    spot: '이사',
    name: '구원경',
    address: '예스코 홀딩스 시니어매니저'
  },
  {
    key: '5',
    spot: '이사',
    name: '공상구',
    address: '마이아트 옥션 대표'
  },
  {
    key: '6',
    spot: '이사',
    name: '김지용',
    address: '국민대학교 이사장'
  },
  {
    key: '7',
    spot: '이사',
    name: '심종현',
    address: '한국가구박물관 관장'
  },
  {
    key: '8',
    spot: '이사',
    name: '이승철',
    address: 'NHN 콘텐츠 총괄 프로듀서'
  },
  {
    key: '9',
    spot: '이사',
    name: '이영기',
    address: '법무법인 LKB&Partners 대표변호사'
  },
  {
    key: '10',
    spot: '이사',
    name: '장윤규',
    address: '국민대 건축학부 교수/운생동 건축사무소 공동대표'
  },
  {
    key: '11',
    spot: '이사',
    name: '전인건',
    address: '간송미술관 관장'
  },
  {
    key: '12',
    spot: '이사',
    name: '조현상',
    address: '효성그룹 부회장'
  },
  {
    key: '13',
    spot: '이사',
    name: '천미전',
    address: '우리옛돌박물관 관장'
  },
  {
    key: '14',
    spot: '이사',
    name: '허광복',
    address: '동덕여대 경영학과 교수'
  },
  {
    key: '15',
    spot: '당연직이사',
    name: '임근수',
    address: '성북구청 행정문화국장'
  },
];
// TABLE3
const data3 = [
  {
    key: '1',
    spot: '운영위원장',
    name: '계영선',
    address: '영 라인의원 원장'
  },
  {
    key: '2',
    spot: '운영위원',
    name: '류지연',
    address: '국립현대미술관 미술품수장센터 운영과장'
  },
  {
    key: '3',
    spot: '운영위원',
    name: '유형균',
    address: '철한정화기업 전무이사'
  },
  {
    key: '4',
    spot: '운영위원',
    name: '이병곤',
    address: '서울 운현궁 관리운영본부장'
  },
  {
    key: '5',
    spot: '운영위원',
    name: '이홍기',
    address: '성균관대학교 법학연구원 선임연구원'
  },
  {
    key: '6',
    spot: '운영위원',
    name: '임석규',
    address: '불교문화재연구소 수석연구관'
  },
  {
    key: '7',
    spot: '운영위원',
    name: '장옥주',
    address: '공감M아트센터 대표'
  },
  {
    key: '8',
    spot: '운영위원',
    name: '채수정',
    address: '한국예술종합학교 전통예술원 교수'
  },
  {
    key: '9',
    spot: '운영위원',
    name: '한민석',
    address: '동덕여자대학교 실용음악과 교수'
  },
  {
    key: '10',
    spot: '운영위원',
    name: '황장훈',
    address: '세무법인 혜움 하남미사지점 대표 세무사'
  },
];
// TABLE4
const data4 = [
  {
    key: '1',
    spot: '고문',
    name: '김희진',
    address: '국민생활체육전국배구연합회 상임이사'
  },
  {
    key: '2',
    spot: '고문',
    name: '마상곤',
    address: '협운해운(주) 회장'
  },
  {
    key: '3',
    spot: '고문',
    name: '박병호',
    address: '전 서울시 문화유산해설사'
  },
  {
    key: '4',
    spot: '고문',
    name: '박태호',
    address: '원제한의원 원장'
  },
  {
    key: '5',
    spot: '고문',
    name: '이병무',
    address: '(주)아세아시멘트 회장'
  },
  {
    key: '6',
    spot: '고문',
    name: '이윤재',
    address: '(주)피죤 회장'
  },
  {
    key: '7',
    spot: '고문',
    name: '이의순',
    address: '(주)세방그룹 명예회장'
  },
  {
    key: '8',
    spot: '고문',
    name: '장치혁',
    address: '고려학술문화재단 명예회장'
  },
  {
    key: '9',
    spot: '고문',
    name: '정근호',
    address: '(주)라이프어패럴, 리더무역 CEO회장'
  },
  {
    key: '10',
    spot: '고문',
    name: '정승소',
    address: '(주)동승 회장'
  },
  {
    key: '11',
    spot: '고문',
    name: '조양래',
    address: '(주)한국타이어 회장'
  },
  {
    key: '12',
    spot: '고문',
    name: '허남각',
    address: '삼양통상(주) 회장'
  },
  {
    key: '13',
    spot: '고문',
    name: '강석원',
    address: '전 한국건축가협회 회장'
  },
  {
    key: '14',
    spot: '고문',
    name: '정하경',
    address: '전 한성대학교 예술대학 학장'
  },
];
// TABLE5
const data5 = [
  {
    key: '1',
    spot: '감사',
    name: '정호섭',
    address: '고려대학교 한국사학과 교수'
  },
  {
    key: '2',
    spot: '감사',
    name: '최한진',
    address: '다율회계법인부대표'
  },
];

function Executives() {

  return (
    <div id="Executives" className="static container">
      <div>
        <p className='TableTitle'>역대원장</p>
        <Table
          columns={columns}
          dataSource={data1}
          pagination={false}
          scroll={{ x: 'fixed' }}
        />
        <p className='TableTitle'>임원</p>
        <Table
          columns={columns}
          dataSource={data2}
          pagination={false}
        />
        <p className='TableTitle'>운영위원</p>
        <Table
          columns={columns}
          dataSource={data3}
          pagination={false}
        />
        <p className='TableTitle'>고문</p>
        <Table
          columns={columns}
          dataSource={data4}
          pagination={false}
        />
        <p className='TableTitle'>감사</p>
        <Table
          columns={columns}
          dataSource={data5}
          pagination={false}
        />
        </div>
    </div>
  );
};



export default Executives;
