import React ,{ useState } from "react";
import "../assets/styles/static.css"; 
import axios from "axios";
import { Link, Route, useHistory, Switch, useLocation } from "react-router-dom";
import boardData from "../data/boardData.json";
import Greetings from "../components/static-page/sbcc/Greetings";
import History from "../components/static-page/sbcc/History";
import OrganizationChart from "../components/static-page/sbcc/OrganizationChart";
import Business from "../components/static-page/sbcc/Business";
import Executives from "../components/static-page/sbcc/Executives";
import Map from "../components/static-page/sbcc/Map";

function Sbcc(props){
  const { groupValue, value } = props;
  let history = useHistory();
  let location = useLocation();

return (
  <>
    <Switch>
      <Route
          name="greetings"
          path="/sbcc/greetings">
        <Greetings 
            location={location}
            value={value} 
            />
      </Route>
      <Route
          name="history"
          path="/sbcc/history">
        <History 
            location={location}
            value={value} 
            />
      </Route>
      <Route
          name="organization-c"
          path="/sbcc/organization-c">
        <OrganizationChart 
            location={location}
            value={value} 
            />
      </Route>
      <Route
          name="business"
          path="/sbcc/business">
        <Business 
            location={location}
            value={value} 
            />
      </Route>
      <Route
          name="executives"
          path="/sbcc/executives">
        <Executives 
            location={location}
            value={value} 
            />
      </Route>
      <Route
          name="map"
          path="/sbcc/map">
        <Map 
            location={location}
            value={value} 
            />
      </Route>
    </Switch>
</>
);

};

export default Sbcc;
