import React from 'react';

function Greertings() {
    return(
        <div id='Greetings' className='static container titleLayout'>
            <div className='Maintitle'>
                <div>
                    <h2><strong>성북문화원</strong></h2>
                    <h3>(Seongbuk Cultural Center)</h3>
                    <p>품격 있는 지역 문화 발전의 요람</p>
                </div>
            </div>
            <div className='ContentWrapper'>
                <p style={{margin:'3%', lineHeight:'1.5', textAlign: 'justify'}}>
                {/*김영일 원장님 인사말<br/><br/>*/}
                지역은 문화의 뿌리입니다.<br/>
                세계의 모든 문화는 지역에 기반을 두고 있습니다.<br/>
                그런 의미에서 지역문화를 지키는 일은,<br/>
                문화의 뿌리를 지키는 일입니다.<br/><br/>
                성북문화원은 1996년 설립이래,<br/>
                성북구 지역문화의 기반을 다지는 역할을 해왔습니다.<br/>
                성북학 연구, 성북마을아카이브 구축,<br/>
                전통예술강좌를 비롯한 각종 문화강좌의 운영과<br/>
                성북동문화재야행을 비롯한 각종 콘텐츠의 개발은<br/>
                이러한 노력의 산물입니다.<br/><br/>
                지역은 창조와 교류의 공간이기도 합니다.<br/>
                사람은 지역이라는 공간 안에서 생활하며, 그곳에서 창조활동을 합니다.<br/>
                성북구 문화예술인들의 교류와 창작활동은 이미 널려 알려진 이야기입니다.<br/><br/>
                성북문화원은 이러한 성북구의 전통을 이어가는 기관입니다.<br/>
                문화예술인은 물론, 모든 주민들이 서로 교류하며<br/>
                각자의 이야기를 나누며, 영감을 얻는 공간이 되도록 노력하겠습니다.<br/><br/>
                문화는 삶의 모습, 그리고 사회의 품격을 보여줍니다.<br/>
                성북구 모든 주민의 삶을 더욱 풍요롭게 만드는,<br/>
                품격 있는 지역문화 발전의 요람, 성북문화원입니다.
                <br/><br/><br/><br/>
                <span style={{display:'flex', justifyContent:'flex-end'}}>성북문화원장<b style={{paddingLeft:'0.5rem'}}>김영일</b></span>
                </p>
            </div>

        </div>
    )
}
export default Greertings;
