import React, { useState, useEffect } from 'react';
import { Modal, Button, Result } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import {createBrowserHistory} from "history";
import {authService} from "../../middleware/fb";

const ModalBtnError = (props) => {
    const { modalDataError, setModalDataClose } = props;
    const history = useHistory();

    const [_modalData, _setModalData] = useState({
        isModalVisible: false
    });

    const getModal = async ()=>{
        // await console.log('변함!',modalDataError);
        // await console.log('변함1!',_modalData);
        await _setModalData(modalDataError);
    }

    useEffect( ()=>{
        getModal().then(r => {
            // console.log('변함2!',_modalData);
        });
    },[modalDataError]);

    const removeModal = () => {
        setModalDataClose();
    };

    const redirect = (url) => {
        if (modalDataError.isModalVisible) {
            authService.signOut().then(()=>{
                removeModal();
                if (!!url) {      //url이 없이 들어오는건 확인 눌러도 꺼지도록
                    history.push(url);
                    console.log('url-',url);
                    createBrowserHistory({      // 로그인 / 회원가입 실패로 인한 로그아웃시 이동 페이지에서 강제 새로고침 - 네이버 로그인쪽 문제 임시방편 by Sarc
                        forceRefresh: true
                    });
                }
            });
        }
    }

    // setTwoToneColor('#F55F4A');
    // getTwoToneColor();

    return (
        <>
            <Modal
                visible={_modalData.isModalVisible}
                footer={null}
            >
                <Result
                    // icon={<CloseCircleOutlined style={{color:'#F55F4A'}} />}
                    status="error"
                    title="확인"
                    subTitle={_modalData?.modalMessage}
                    extra={
                        <Button type="primary" onClick={()=>{redirect(_modalData?.modalUrl)}}>
                            확인
                        </Button>
                    }
                />
            </Modal>
        </>
    );
}

export default ModalBtnError;
