import React from "react";
import { Result, Button, Space } from 'antd';


function NotFound(){
    return (
        <div style={{ width:"100vw", height:"100%", margin:"0 auto", paddingTop:"20vh"}}>
                <Result
                    status="404"
                    title="404"
                    subTitle="페이지를 찾을 수 없습니다."
                    extra={<Button type="primary" href="/">메인으로 돌아가기</Button>}
                />
        </div>
        );
    
};

export default NotFound;