import React from "react";
import { useHistory, Route, Switch } from "react-router-dom";
import GalleryList from "../gallery/GalleryList";
import GalleryDetail from "../gallery/GalleryDetail";

function Eacademic(props){
    const {type, group, init, userObj} = props;
    const cate = 'e-academic'
    return (
        <>
        <Switch>
            <Route path="/event/e-academic/" exact >
                <GalleryList cate={cate} type={type} group={group} init={init} userObj={userObj} />
            </Route>
            <Route path="/event/e-academic/detail/:bbsId" exact >
                <GalleryDetail cate={cate} type={type} group={group} />
            </Route>
        </Switch>
        </>
        );

};

export default Eacademic;
