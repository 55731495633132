import React ,{ useState, useEffect }from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import axios from "axios";
import GalleryList from "../gallery/GalleryList";
import GalleryDetail from "../gallery/GalleryDetail";

function Eculrture(props){
    const {type, group, init, userObj} = props;
    const history = useHistory();
    const cate = 'e-culrture'
    return (
        <>
        <Switch>
            <Route path="/event/e-culrture/" exact>
                <GalleryList cate={cate} type={type} group={group} init={init} userObj={userObj} />
            </Route>
            <Route path="/event/e-culrture/detail/:bbsId" exact>
                <GalleryDetail history={history} cate={cate} type={type} group={group} />
            </Route>
        </Switch>
        </>
        );

};

export default Eculrture;
