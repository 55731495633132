import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom'
import { Typography } from 'antd';
import { defaultApiAddress } from '../../services/api.helpers';
import WithoutSkeletonG from './WithoutSkeletonG';
import WithSkeletonG from './WithSkeletonG';
import '../../assets/styles/galleryboard.css';

const { Title, Text } = Typography;

export default function GalleryDetail(props) {
	const { cate } = props;
    const history = useHistory();
    const { bbsId } = useParams();
    const pathName =  history.location.pathname;

    // console.log('게시글 번호 : ' + bbsId, '게시판 경로 : ' + pathName);

    const [ detailData, setDetailData ] = useState(null)
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        let ignore = false;
        async function fetchDetailData() {
            setDetailData(null);
            const result = await axios.get(`${defaultApiAddress}/boards/${bbsId}?cate=${cate}`);
               if(!ignore) { 
                 setDetailData(result.data);
                 console.log(result.data);
                 setLoading(false);
                }
          }
          fetchDetailData();
        return () => { ignore = true }

      }, [history.location.pathname]);

    return(
		<>
			{ loading ? 
				<WithSkeletonG detailData={detailData} loading={loading} /> : 
					<WithoutSkeletonG
						history={history}
						detailData={detailData} 
						loading={loading}
                        pathName={pathName}
                        /> }
			</>
    )
}