import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom'
import { Space, Button, Typography, Row, Col, Modal, Form, Divider, List, Spin, Skeleton } from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined, MessageOutlined, LikeOutlined, StarOutlined, EyeOutlined } from '@ant-design/icons';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';

const { Title, Text } = Typography;

export default function WithSkeleton(props) {
    const { userObj, history, data, detailData, isLoading } = props;
    return(
    <>
      <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
        <Row className="detailMainInfoWrap">
            <Col span={2}>
                <Button type="text">
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            </Col>
            <Col span={2} offset={18}>
            </Col>
            <Col span={2} offset={0}>
            </Col>
            <Col span={24} style={{margin:"50px 0 25px", padding:"0 20px"}}>
                <Title className="titleText">
                    <Skeleton.Input style={{ width: 200 }} active size="large" />
                </Title>
                <Text type="secondary" className="secondaryText">
                    <Space>
                     <Skeleton.Input style={{ width: 64 }} active size="small" />
                    </Space>  
                </Text>
                <Divider className="titleDivider" />
                
            </Col>
        </Row>
        <Row align="middle" justify="center" style={{padding:"0 24px 24px",width:"100%", height:"500px"}} >
            <Skeleton active />
        </Row>
        <Row align="middle" justify="center" className="detailFooterWrap">
            <Divider className="titleDivider" />
            <div className="detailIconWrap" style={{ height: "500px", padding:"50px 0"}} >
                    <Skeleton active />
            </div>
            <Col span={24} style={{margin:"0 0 25px"}}>
                <List
                    className="prevnextList"
                    itemLayout="horizontal"
                >
                    <List.Item
                    actions={[<a key="list-loadmore-edit" ><UpOutlined />ㅤ다음글</a>, <a key="list-loadmore-more" ><Skeleton.Input style={{ width: 100 }} size="small" active /></a>]}
                    />
                    <List.Item
                    actions={[<a key="list-loadmore-edit" ><DownOutlined />ㅤ이전글</a>, <a key="list-loadmore-more" ><Skeleton.Input style={{ width: 100 }} size="small" active /></a>]}
                    />
                </List>
            </Col>
        </Row>
      </Row>
      </>
    );
}
