
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import React, { useState, useEffect} from "react";
import {Space, Button, Typography, Row, Col, Divider, List, Image } from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { defaultApiAddress } from '../../services/api.helpers';

const { Title, Text } = Typography;

export default function WithoutSkeletonG(props) {
    const { history, detailData } = props;
    const location = useLocation();

    const [ prevLink, setprevLink] = useState({
        link:null,
        title:null,
    });

    const [ nextLink, setnextLink] = useState({
        link:null,
        title:null,
    });

    const curpathpoint = location.pathname.indexOf('/detail/');
    const prevPath = location.pathname.slice(0, curpathpoint);
    const curpath = location.pathname.slice(0, curpathpoint + 8);

    const prevFunction = () => {
        if(detailData?.nearRow[0] === null){
            setprevLink({
                link:null,
                title:'이전 게시물이 존재하지 않습니다.'
            })
        }else if(detailData.nearRow[0] !== null){
            setprevLink({
                link:curpath + detailData.nearRow[0].id,
                title:detailData.nearRow[0].title
                })
            }
    }


    const nextFunction = () => {
        if(detailData?.nearRow[1] !== null){
            setnextLink({
               link:curpath + detailData.nearRow[1].id,
               title:detailData.nearRow[1].title
           })
       }else{
           setnextLink({
               link:null,
               title:'다음 게시물이 존재하지 않습니다.'
           })
       }
    }

    useEffect(() => {
        prevFunction();
        nextFunction();
        return () => null;
        },
    [detailData?.nearRow],
    );

    const onHandleBack = () => {
        history.push(prevPath);
    };

    return(
    <>
      <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
        <Row className="detailMainInfoWrap">
            <Col span={2}>
                <Button onClick={onHandleBack} type="text">
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            </Col>
            <Col span={2} offset={18}>
            </Col>
            <Col span={2} offset={0}>
            </Col>
            <Col span={24} style={{margin:"50px 0 0", padding:"0 20px"}}>
                <Title className="titleText">
                    { detailData?.title }
                </Title>
                <Text type="secondary" className="secondaryText">
                     <Space>
                       성북문화원
                       { moment(detailData?.createdAt).format('YYYY/MM/DD') }
                     </Space>  
                </Text>
                <Divider className="titleDivider" />
                
            </Col>
        </Row>
        <Row align="middle" justify="center" style={{padding:"0 24px 74px"}} >
        <div className='g-b-container'>
                <div className='boardTitleBox'>
                    </div>
                    <hr/>
                <Row className='g-b-table'>
                    <Col span={12} className="leftTableImage">
                        <Image 
                            src={defaultApiAddress+detailData?.mediaUrl}
                            alt={detailData?.title} 
                            width="100%"
                            preview={false}
                            title={detailData?.title}
                            placeholder={true}
                        />
                    </Col>
                    <Col span={12} className="rightTableContent">
                        <table className="tbl">
                            <tbody><tr>
                                <th>행사명</th>
                                <td>{detailData?.info?.eventName}</td>
                            </tr>
                            <tr>
                                <th>일시</th>
                                <td>{detailData?.info?.eventDate}</td>
                            </tr>
                            <tr>
                                <th>장소</th>
                                <td>{detailData?.info?.eventLocation}</td>
                            </tr>
                            <tr>
                                <th>공연정보</th>
                                <td>
                                    {detailData?.info?.eventDescription}				
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        </Row>
        <Row align="middle" justify="center" className="detailFooterWrap">
            <Divider className="titleDivider" />
            <Col span={24} style={{margin:"0 0 25px"}}>
                <List
                    className="prevnextList"
                    itemLayout="horizontal"
                >
                    <List.Item
                    actions={[<a key="list-loadmore-edit" href={nextLink?.link}><UpOutlined />ㅤ다음글</a>, <a key="list-loadmore-more" href={nextLink?.link}>{nextLink?.title}</a>]}
                    />
                    <List.Item
                    actions={[<a key="list-loadmore-edit" href={prevLink?.link}><DownOutlined />ㅤ이전글</a>, <a key="list-loadmore-more" href={prevLink?.link}>{prevLink?.title}</a>]}
                    />
                </List>
            </Col>
        </Row>
      </Row>
      </>
    );
}
