import React from 'react';
import ContentImg1 from '../../../assets/img/business-content-1.jpg'
import ContentImg2 from '../../../assets/img/business-content-2.jpg'
import ContentImg3 from '../../../assets/img/business-content-3.jpg'

function Business(){
    return(
        <div id='Business' className='static container titleLayout'>

                <div className='Maintitle'>
                    <div>
                        <h2>성북문화원 주요 사업</h2>
                        <p>문화교육 - 지역문화 축제 및 문화행사 - 성북학 연구</p>
                    </div>
                </div>
            {/* content1 */}
            <div className='contentsWrapper'>
                <h3 className='Subtitle'>문화교육</h3>
                <div className='contents'>
                    <div><img src={ContentImg1} alt='문화강좌 현장'></img></div>
                    <div>
                        <ul>
                            <p> 전통문화예술 보존 및 계승을 위한 전통강좌는 물론 지역민의 평생교육을 위한 일반생활교양, 어린이강좌 등 다양한 문화강좌를 운영합니다.</p>
                            <li>전통강좌<br/></li>
                            <li>생활교양강좌</li>
                            <li>어린이강좌</li>
                        </ul>
                    </div>
                </div>
                <div className='clear'/>
            </div>
            {/* content2 */}
            <div className='contentsWrapper'>
                <h3 className='Subtitle'>지역문화 축제 및 문화행사</h3>
                <div className='contents'>
                    <div><img src={ContentImg2} alt='성북문화재 현장'></img></div>
                    <div>
                        <ul>
                            <p> 성북구의 역사·문화 자원을 기반으로 관내 예술인들과 함께 성북구만의 콘텐츠를 기획·운영합니다. 이를 통해 지역민에게 예술 향유의 기회를 제공하고, 성북의 역사와 문화를 알리는 역할을 합니다.</p>
                            <li>성북동 문화재 야행</li>
                            <li>역사인물 선양사업 (만해 한용운 선사 다례재 및 이육사 탄생 기념행사 등)</li>
                            <li>캠퍼스타운 축제</li>
                            <li>문화가 있는 날 등 다수</li>
                        </ul>
                    </div>
                </div>
                <div className='clear'/>
            </div>
            {/* content3 */}
            <div className='contentsWrapper'>
                <h3 className='Subtitle'>성북학 연구</h3>
                <div className='contents'>
                    <div><img src={ContentImg3} alt='성북구 주민기록단 교육 현장'></img></div>
                    <div>
                        <ul>
                        <p>성북의 역사·문화·생활 분야 자원을 조사하고 연구합니다. <br/>
                            성북마을아카이브 홈페이지를 구축·운영하고
                            학술회의와 강연회를 개최하고 도서를 발간하여 역사문화도시
                            성북을 만들기 위한 기초자료를 생산합니다.</p>
                            <li>성북마을아카이브 구축 및 운영</li>
                            <li>성북학 학술회의 개최 및 성북학 도서 발간</li>
                            <li>성북구 주민기록단 교육 및 활동</li>
                        </ul>
                    </div>
                </div>
                <div className='clear'/>
            </div>
        </div>

    )
}
export default Business;
