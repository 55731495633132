// 로그인 페이지 컴포넌트(임시)
// src/components/common/Auth.jsx
import React, { useState, useEffect } from "react";
import {Router, NavLink, useHistory, Link, Route, Redirect, Switch, BrowserRouter, useLocation} from "react-router-dom";
import { authService, firebaseInstance } from "../../middleware/fb";
import axios from "axios";
import { defaultApiAddress } from "../../services/api.helpers"
import KakaoSignin from "./KakaoSignin";
import NaverSignin from "./NaverSignin";
import "../../assets/styles/Mypage.css";
import {Button, Checkbox, Col, Form, message, Row, Tabs} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import SignupBtn from "./SignupBtn";
import ModalBtnNotice from '../layout/ModalBtnNotice';
import ModalBtnError from '../layout/ModalBtnFail';
import TermsOfUse from '../static-page/terms/TermsOfUse';
import PersonalTerm from '../static-page/terms/PersonalTerm';
import ModalBtnFail from "../layout/ModalBtnFail";
import LoadingSpin from "./LoadingSpin";
import UserExistCheck from "./UserExistCheck";

const { TabPane } = Tabs;

const Auth = (props) => {
    const {userObj, setUserObjFn, setInitFn } = props;

    const history = useHistory();
    const location = useLocation();
    const [mode, setMode] = useState(false);    // false 비로그인 true 로그인상태
    const [validated, setValidated] = useState(false);    // false 비로그인 true 로그인상태
    const setModeFn = function (e) {
        setMode(e);
    }
    const [modalData, setModalData] = useState({
        isModalVisible: false
    });
    const [modalDataError, setModalDataError] = useState({
        isModalVisible: false
    });
    const setModalDataClose = ()=>{
        setModalData({
            isModalVisible: false
        })
        setModalDataError({
            isModalVisible: false
        })
    }

    const [infoInputVisible, setInfoInputVisible] = useState(false);    // 유저 정보 입력창 활성화
    const setInfoInputVisibleFn = () => {
        setInfoInputVisible(true)
    }

    useEffect(() => {
        setInitFn(true);

        // 익플 막기
        if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            window.location = 'microsoft-edge:' + window.location;

            setTimeout(function() {
                window.location = 'https://go.microsoft.com/fwlink/?linkid=2135547';
            }, 100);
        } else {
            // console.log('브라우져-',navigator.userAgent);
        }

        if (window.location.pathname.includes('signup')) {
            setMode('signup');
        } else if (window.location.pathname.includes('signin')) {
            setMode('login');
            // callback();
        }
        // console.log(history?.location?.state);
        // setLocationState(history?.location?.state);
        return () => {
            // console.log('useEffect Broken - pathname');
        }
    }, [location.pathname]);

    useEffect(() => {
        if (mode == 'signup') {
            if (!window.location.pathname.includes('signup')) {
                history.push("/signup");
                // console.log('signup path push');
            }
        } else if (mode == 'login') {
            if (!window.location.pathname.includes('signin')) {
                history.push("/signin");
                // console.log('login path push');
            }
        }
        return () => {
            // console.log('useEffect Broken - mode');
        }
    }, [mode]);

    async function onChange(e) {
        // console.log(`checked = ${e.target.checked}`);
        let _unchecked = false;
        let _inputs = document.getElementsByTagName("input");

        for (let i = 0, max = _inputs.length; i < max; i++){
            if (_inputs[i].type === 'checkbox')
                if (_inputs[i].checked) {

                } else {
                    _unchecked = true;      // 체크 안된게 있다!
                }
        }

        if (!_unchecked) {
            await setValidated(true);
        } else {
            await setValidated(false);
        }
    }
    function callback(key) {
        console.log(key);
        let _key = key*1;
        if (_key === 2) {        // 현재 있는 탭 말고 다른 탭 선택시
            if (window.location.pathname.includes('signin')) {
                console.log('path change');
                history.push("/signup");
                setMode('signup');
                document.getElementsByClassName('ant-tabs-tab')[0].click();
            } else if (window.location.pathname.includes('signup')) {
                console.log('path change');
                history.push("/signin");
                setMode('login');
                document.getElementsByClassName('ant-tabs-tab')[1].click();
            }
        }
    }

    const onSocialClick = async (event) => {
        // 약관동의 validation
        if (window.location.pathname.includes('signup') && !validated) {
            // message.error('회원가입시 이용약관 및 개인정보처리방침 동의가 필요합니다.');       // 이거없이 자동 강조
            return false;
        }
        const {
            target: { name }
        } = event;
        const _flag = event.target.getAttribute('data-mode');   // 로그인 / 가입 모드 분기
        setMode(_flag);     // login or signup(태그에서 data-mode로 세팅
        let provider;

        if (name === "google") {
            provider = new firebaseInstance.auth.GoogleAuthProvider();
        } else if (name === "facebook") {
            provider = new firebaseInstance.auth.FacebookAuthProvider();
        }

        await authService.signInWithPopup(provider).then(async (res)=>{
            const instance = await axios.create({
                baseURL: defaultApiAddress,
                headers: {
                    "Authorization" : res.user._lat,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                },
                timeout: 5000,
            });
            const response = await instance.get(
                'users/' + res.user.uid
            ).then(async response => {  // 회원정보 획득 성공시 -> 로그인
                if (_flag === 'signup') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                    onLogoutClick();
                    setModalDataError({
                        isModalVisible: true,
                        modalMessage: '이미 가입된 계정입니다! 로그인해 주세요.',
                        modalUrl: '/signin'
                    });
                    setMode('login');
                } else if (_flag === 'login') {
                    setMode('done');
                }
                return false;
            }).catch(async (error) => {
                if (_flag === 'login') {    // 회원 가입 모드에서 이쪽으로 오면 튕겨야함.
                    setModalDataError({
                        isModalVisible: true,
                        modalMessage: '가입이력이 없습니다! 회원가입해 주세요.',
                        modalUrl: '/signup'
                    });
                    onLogoutClick();
                    // return false;
                } else if (_flag === 'signup') {
                    // 회원정보 획득 실패시 -> 신규가입
                    setInfoInputVisible(true);
                    const instance = await axios.create({
                        baseURL: defaultApiAddress,
                        headers: {
                            "Authorization" : res.user._lat,
                            "Content-Type" : "application/json",
                            "Access-Control-Allow-Origin" : "*",
                        },
                        timeout: 5000,
                    });
                    try {
                        await instance.put(
                            'users/' + res.user.uid,
                            {id: res.user.uid}
                        ).then(response => {
                            if (response.data.userNameReal && response.data.phoneNumber) {
                                setUserObjFn(() => {
                                    let _userObj = {...userObj};
                                    _userObj.userNameReal = response.data.userNameReal;
                                    _userObj.phoneNumber = response.data.phoneNumber;
                                    _userObj.provider = response.data.provider;
                                    _userObj.uid = response.data.id;
                                    _userObj.email = response.data.email;
                                    _userObj.displayName = response.data.userName;
                                    _userObj.photoURL = response.data.photoUrl;
                                    return _userObj;
                                });
                            } else {
                                setUserObjFn(() => {
                                    let _userObj = {...userObj};
                                    _userObj.provider = response.data.provider;
                                    _userObj.uid = response.data.id;
                                    _userObj.email = response.data.email;
                                    _userObj.displayName = response.data.userName;
                                    _userObj.photoURL = response.data.photoUrl;
                                    return _userObj;
                                });
                            }
                            // console.log(userObj);
                        });
                    } catch (error) {
                        if (error.response) {
                            console.log(error.response.data);
                            if (error.response.data.message === '"SequelizeUniqueConstraintError: Validation error"') {
                                // userId는 다르지만, 회원 db상 이미 동일한 이메일이 존재하는 경우 발생하는 에러. userId 수정이 필요할 수 있음 - by Sarc
                                onLogoutClick();
                                setModalDataError({
                                    isModalVisible: true,
                                    modalMessage: '해당 계정은 사용이 불가능합니다! 담당자에게 문의해 주세요.',
                                    modalUrl: '/'
                                });
                            }
                        }
                    }
                }
            });
        });
    };

    const onLogoutClick = () => {
        authService.signOut();
    };
    const goBack = (e) => {
        e.preventDefault();
        history.goBack()
    };
    const userInfoUpdate = async (e) => {
        e.preventDefault();
        let _userNameReal = document.getElementById("userNameReal").value;
        let _phoneNumber = document.getElementById("phoneNumber").value;
        // console.log(_userNameReal);
        const instance = await axios.create({
            baseURL: defaultApiAddress,
            // baseURL: 'http://localhost:8081/',
            headers: {
                "Authorization" : userObj.idToken,  //TODO 일단 로그인 과정중 최신토큰을 사용하도록 되어있고, 사용되는지 불명확, 우선 놔둠 by Sarc
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 10000,
        });
        try {
            const response = await instance.put(
                'users/' + userObj.uid,
                {'userNameReal' : _userNameReal, 'phoneNumber' : _phoneNumber}
            ).then(response => {
                setUserObjFn(() => {
                    let _userObj = {...userObj};
                    _userObj.userNameReal = response.data.userNameReal;
                    _userObj.phoneNumber = response.data.phoneNumber;
                    _userObj.provider = response.data.provider;
                    _userObj.uid = response.data.id;
                    _userObj.email = response.data.email;
                    _userObj.displayName = response.data.userName;
                    _userObj.photoURL = response.data.photoUrl;
                    return _userObj;
                });
                // alert('이름/전화번호 등록 완료!');
                setModalData({
                    isModalVisible: true,
                    modalMessage: '이름/전화번호 등록 완료!',
                    modalUrl: '/mypage'
                });
            });
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
            }
        }
    };

    let authPage;
    if (mode == 'not-registered') {
        authPage =<>
            <ModalBtnFail modalDataError={{
                isModalVisible: true,
                modalMessage: '가입이력이 없습니다!',
                modalUrl: '/signup'
            }} setModalDataClose={setModalDataClose} />
        </>;
    } else if (mode == 'already-registered') {
        authPage =<>
            <ModalBtnFail modalDataError={{
                isModalVisible: true,
                modalMessage: '이미 가입된 계정입니다! 로그인해 주세요.',
                modalUrl: '/signin'
            }} setModalDataClose={setModalDataClose} />
        </>;
    } else if (userObj == null) {
        if (mode === 'login') {
            authPage =<>
                <div className='logcontainer'>
                    <div className='loginTabBox'>
                        <Tabs onChange={callback} type="card" defaultActiveKey="1">
                            <TabPane className='loginTab' tab="로그인" key="1">
                                <div className='btnBox'>
                                    <NaverSignin userObj={userObj} setUserObjFn={setUserObjFn} setInfoInputVisibleFn={setInfoInputVisibleFn} setModeFn={setModeFn}/>
                                    <button className='before googleBtn' onClick={onSocialClick} name="google" data-mode="login">구글 간편 로그인</button>
                                    <KakaoSignin userObj={userObj} setUserObjFn={setUserObjFn} setInfoInputVisibleFn={setInfoInputVisibleFn} isLogin='login' setModeFn={setModeFn}/>
                                    {/*<button className='before facebBtn' onClick={onSocialClick} name="facebook" data-mode="login">페이스북 간편 로그인</button>*/}
                                </div>
                                <button className='joinBtn' onClick={()=>{document.getElementsByClassName('ant-tabs-tab')[1].click()}}><sub>아직 회원이 아니라면</sub><p>회원가입 하러가기</p></button>
                            </TabPane>
                            <TabPane className='joinTab' tab="회원가입" key="2">
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </>;
        } else if (mode === 'signup') {
            authPage =<>
                <div className='logcontainer'>
                    <div className='loginTabBox'>
                        <UserExistCheck />
                        <Tabs onChange={callback} type="card" defaultActiveKey="1">
                            <TabPane className='loginTab' tab="로그인" key="2">
                            </TabPane>
                            <TabPane className='joinTab' tab="회원가입" key="1">
                                <Form>
                                    <div className='termsBox'>
                                        <p><ExclamationCircleFilled style={{color:'#FFD560'}}/> 이용약관</p>
                                        <div className="terms">
                                            <TermsOfUse />
                                        </div>
                                        <Form.Item
                                            name="term1"
                                            defaultValue={false}
                                            valuePropName="checked"
                                            style={{height:'32px'}}
                                            rules={[{ required: true, message: <span style={{marginLeft:'24px'}}>* 회원가입시 이용약관 동의가 필요합니다.</span>}]}
                                        >
                                            <Checkbox onChange={onChange}>위 약관을 모두 인지하였으며 이에 동의합니다</Checkbox>
                                        </Form.Item>

                                    </div>
                                    <div className='termsBox'>
                                        <p><ExclamationCircleFilled style={{color:'#FFD560'}}/> 개인정보처리방침</p>
                                        <div className="terms">
                                            <PersonalTerm />
                                        </div>
                                        <Form.Item
                                            name="term2"
                                            defaultValue={false}
                                            valuePropName="checked"
                                            style={{height:'32px'}}
                                            rules={[{ required: true, message: <span style={{marginLeft:'24px'}}>  * 회원가입시 개인정보처리방침 동의가 필요합니다.</span>}]}
                                        >
                                            <Checkbox onChange={onChange}>위 약관을 모두 인지하였으며 이에 동의합니다</Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className='btnBox' htmlType="">
                                        <NaverSignin userObj={userObj} validated={validated} setUserObjFn={setUserObjFn} setInfoInputVisibleFn={setInfoInputVisibleFn} setModeFn={setModeFn}/>
                                        <button className='before googleBtn' onClick={onSocialClick} name="google" data-mode="signup">구글 간편 회원가입</button>
                                        <KakaoSignin userObj={userObj} validated={validated} setUserObjFn={setUserObjFn} isLogin='signup' setInfoInputVisibleFn={setInfoInputVisibleFn} setModeFn={setModeFn}/>
                                        {/*<button className='before facebBtn' onClick={onSocialClick} name="facebook" data-mode="signup">페이스북 간편 회원가입</button>*/}
                                    </div>
                                </Form>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </>;
        }
        // } else if (((mode === 'signup') && (!userObj.userNameReal || !userObj.phoneNumber))) {
    } else if (mode == 'done') {
        // 현재 제일 보편적인 성공 시나리오
        console.log('로그인 완료!');
        history.push("/");
        // authPage =<>
        //     <ModalBtnNotice modalData={{
        //         isModalVisible: true,
        //         modalMessage: '로그인 되었습니다!',
        //         modalUrl: '/'
        //     }} setModalDataClose={setModalDataClose} />
        // </>;
    } else if (mode == 'naver-done') {
        // 네이버 로그인 성공시 분기
        let _check = (window.location.pathname.includes('signin')) ? true : false;
        // console.log('mode-',mode);

        if (mode == 'naver-done') {
            console.log('로그인 완료! - Naver');
            history.push("/");
            // authPage =<>
            //     <ModalBtnNotice modalData={{
            //         isModalVisible: {_check},
            //         modalMessage: '로그인 되었습니다!',
            //         modalUrl: '/'
            //     }} setModalDataClose={setModalDataClose} />
            // </>;
        }
    } else if ((!userObj?.userNameReal || !userObj?.phoneNumber) && (mode !== 'done')) {
        if (mode === 'signup') {
            authPage =<>
                <Col span={24} style={{margin:"50px 0 25px"}}>
                    {
                        <SignupBtn userObj={userObj} history={history} infoInputVisible={infoInputVisible} setInfoInputVisibleFn={setInfoInputVisibleFn} setUserObjFn={setUserObjFn} setModeFn={setModeFn} />
                    }
                </Col>
            </>;
        }
    } else if (mode === 'login') {
        // 로그인 성공분기 2(네이버)
        let _uidChk = userObj ? userObj?.uid+'' : '';
        let _isNaver = _uidChk.includes('naver');
        //로그아웃 후 새로고침 없이 로그인하는 시나리오
        if (_isNaver && userObj?.uid !== null) {
            console.log('로그인 완료!');
            history.push("/");
            // authPage =<>
            //     <ModalBtnNotice modalData={{
            //         isModalVisible: {_isNaver},
            //         modalMessage: '로그인 되었습니다!',
            //         modalUrl: '/'
            //     }} setModalDataClose={setModalDataClose} />
            // </>;
        }
    } else if (userObj.uid != null || userObj.uid !== '') {
        authPage = <LoadingSpin />;
    }

    return (
        <>
            {authPage}
            <ModalBtnNotice modalData={modalData} setModalDataClose={setModalDataClose}/>
            <ModalBtnError modalDataError={modalDataError} setModalDataClose={setModalDataClose}/>
        </>
    )
};

export default Auth;
