import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { List, Typography ,Image, Select, Row, Col } from 'antd';
import { defaultApiAddress } from '../../services/api.helpers';
import "../../assets/styles/Board(CulturalHfigure).css";

const { Text } = Typography;
const { Option } = Select;

const TextList = (props) => {
  const { data, pathName, cate, onCateChange, cateData } = props;
  const [ listData, setListData ] = useState(null);

  const [ depthOne, setDepthOne ] = useState("");
  const [ depthTwo, setDepthTwo ] = useState("");

  useEffect(() => {
    if(depthOne != "" || depthTwo != ""){
          setListData(cateData);
        }else{
          setListData(data);
        }
  },[cateData])

  console.log('list data is :'+depthOne, depthTwo, listData)

  const handleChangeFirst = (value) => {
    setDepthOne(value);
  }

  const handleChangeSecound = (value) => {
    setDepthTwo(value);
  }

  useEffect(() => {
    onCateChange({ depth1: depthOne, depth2: depthTwo });
  }, [depthOne, depthTwo]);


  return (
    <div className="galleryTextList">
        <Row className="depth-row">
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        { cate === 'cultural' ?
                                <Select
                                  size="large"
                                  placeholder="시대 선택"
                                  onChange={handleChangeFirst}
                                  className="depth-select"
                                  // defaultValue={cateData?.depth1}
                                >
                                    <Option value=""><b>전체 시대</b></Option>
                                    <Option value="고대">고대</Option>
                                    <Option value="고려시대">고려시대</Option>
                                    <Option value="조선시대">조선시대</Option>
                                    <Option value="일제강점기">일제강점기</Option>
                                    <Option value="대한민국">대한민국</Option>
                                </Select>
                                : cate === 'hfigure' ?
                                <Select
                                  size="large"
                                  placeholder="시대 선택"
                                  onChange={handleChangeFirst}
                                  className="depth-select"
                                >
                                    <Option value=""><b>전체 시대</b></Option>
                                    <Option value="고대">고대</Option>
                                    <Option value="고려시대">고려시대</Option>
                                    <Option value="조선시대">조선시대</Option>
                                    <Option value="일제강점기">일제강점기</Option>
                                    <Option value="대한민국">대한민국</Option>
                                </Select>
                                : null }
                                { cate === 'cultural' ?
                                    <Select
                                      size="large"
                                      placeholder="종목 선택"
                                      onChange={handleChangeSecound}
                                      className="depth-select"
                                      // defaultValue={cateData?.depth2}
                                    >
                                        <Option value=""><b>전체 종목</b></Option>
                                        <Option value="국보">국보</Option>
                                        <Option value="보물">보물</Option>
                                        <Option value="사적">사적</Option>
                                        <Option value="명승">명승</Option>
                                        <Option value="천연기념물">천연기념물</Option>
                                        <Option value="국가무형문화유산">국가무형문화유산</Option>
                                        <Option value="국가민속문화유산">국가민속문화유산</Option>
                                        <Option value="국가등록문화유산">국가등록문화유산</Option>
                                        <Option value="시도유형문화유산">시도유형문화유산</Option>
                                        <Option value="시도무형문화유산">시도무형문화유산</Option>
                                        <Option value="시도기념물">시도기념물</Option>
                                        <Option value="시도민속문화유산">시도민속문화유산</Option>
                                        <Option value="시도등록문화유산">시도등록문화유산</Option>
                                        <Option value="문화유산자료">문화유산자료</Option>
                                        <Option value="서울미래유산">서울미래유산</Option>
                                        <Option value="국가지정기록물">국가지정기록물</Option>
                                    </Select>
                                : cate === 'hfigure' ?
                                    <Select
                                      size="large"
                                      placeholder="지역 선택"
                                      onChange={handleChangeSecound}
                                      className="depth-select"
                                    >
                                        <Option value=""><b>전체 지역</b></Option>
                                        <Option value="길음동">길음동</Option>
                                        <Option value="돈암동">돈암동</Option>
                                        <Option value="동선동">동선동</Option>
                                        <Option value="보문동">보문동</Option>
                                        <Option value="삼선동">삼선동</Option>
                                        <Option value="석관동">석관동</Option>
                                        <Option value="성북동">성북동</Option>
                                        <Option value="안암동">안암동</Option>
                                        <Option value="월곡동">월곡동</Option>
                                        <Option value="장위동">장위동</Option>
                                        <Option value="정릉동">정릉동</Option>
                                        <Option value="종암동">종암동</Option>
                                    </Select>
                                : null }
                        </Col>
                    </Row>
    { listData !== null ?
        <List
          itemLayout="vertical"
          className="gallery-text-type-list"
          size="large"
          dataSource={listData}
          renderItem={item => (
            <List.Item  key={item.id}>
            <List.Item.Meta
              avatar={
                <Image
                src={defaultApiAddress+item.mediaUrl}
                alt={item.title}
                preview={false}
                width={398}
                height={266}
              />
              }
              title={<Link to={`${pathName}/detail/${item.id}`}>
                  <div className="depth">
                      {item.depth1} ㆍ {item.depth2}
                  </div>
                  <div className="title">
                      {item.title}
                  </div>
                  <div className="description">
                      {/* regex 처음부터, 태그삭제, 두칸 이상 공백기호 제거, 두칸 이상 공백 제거 */}
                      {!!item.description ? item.description.replace(/<[^>]+>/g, "").replace(/\&nbsp;/g, "").replace(/ㅤ{2,}/g," ").replace(/\s{2,}/g," ") : ''}
                  </div>
            </Link>
                  }
              // description={'설명 들어가는 부분의 텍스트'}
            />
              <div className="dateTime">
                <Text type="secondary">{moment(item.createdAt).format('YYYY/MM/DD')}</Text>
              </div>
            </List.Item>
          )}
        />
        : null }
    </div>
    );
  }


  export default TextList;
