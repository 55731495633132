import React, { useState, useRef }  from 'react';
import axios from "axios";
import { EditOutlined, UserAddOutlined, ReloadOutlined } from '@ant-design/icons';
import { Input, InputNumber, Modal, Button, Typography, Form, Row, Col, Radio, Tooltip } from 'antd';
import { defaultApiAddress } from "../../services/api.helpers";
import {useHistory} from "react-router-dom";

const { Text, Title } = Typography;
const { useForm } = Form;

const UserExistCheck = (props) =>{
    const { rowRecord, getNewData, getoffuser } = props;
    const [ userExistCheckModal, setUserExistCheckModal ] = useState(true);
    const [ SearchLoading, setSearchLoading] = useState(false);
    const [ confirm, setConfirm ] = useState(false);
    const [ userData, setUserData ] = useState(null);
    const [ errMsg, setErrorMsg ] = useState(false);
    const [ ettMessage, setErrorMessage ] = useState({
        title: '',
        subtitle: ''
    });
    const history = useHistory();

    const [ state, setState ] = useState({
        userNameReal: '',
        phoneNumber: '',
    })

    const [form]= useForm();

// Cancel Button
    const cancel = () => {
        form.resetFields();
        setConfirm(false);
        setErrorMsg(false);
        setSearchLoading(false);
        setUserExistCheckModal(false);
        history.push('/');
    };

    const toLogin = () => {
        form.resetFields();
        setConfirm(false);
        setErrorMsg(false);
        setSearchLoading(false);
        setUserExistCheckModal(false);
        history.push('/signin');
    };

    const toHome = () => {
        history.push('/');
    };

    const reload = () => {
        form.resetFields();
        setConfirm(false);
        setErrorMsg(false);
        setSearchLoading(false);

        // 전화번호 입력란 위쪽 자동파싱된 연락처 삭제
        let y = document.getElementById("phoneResult");
        y.innerText = '';
    }

// Button Loading Control
    const loadingOff = () => {
        setTimeout(() => {
            return setSearchLoading(false)
        }, 1000)
    }

// user Search
    const onUserSubmit = async(value) => {
        setSearchLoading(true);
        await submitEvent(value);
    }

// axios search (조회) event
    const submitEvent = (value) => {
        let tel = value.phoneNumber;
        if(value.phoneNumber == null){
            return false;
        }else{
            axios.get(`${defaultApiAddress}/usercheck?search=${tel}`)
                .then(res => {
                    switch(res.data.results){
                        case 'onlineUser' : setConfirm('onlineUser');loadingOff();return (
                            setErrorMessage({
                                title: `${res?.data?.info?.provider} (으)로 가입한 온라인 회원입니다.`,
                                subtitle: `로그인해 주시기 바랍니다.`,
                            }),
                                setErrorMsg(true)
                        );break;
                        // case 'offlineUser' : return setUserData(res?.data?.info), offlineUserUpdate(res?.data?.info.userId, value), setErrorMsg(false); break;
                        case 'offlineUser' : setConfirm('offlineUser');loadingOff();return (
                            setErrorMessage({
                                title: `오프라인 회원으로 가입되어 있습니다.`,
                                subtitle: <><span style={{display:"inline-block"}}>담당자에게 문의해주시기 바랍니다.</span></>
                            }),
                                setErrorMsg(true)
                        );break;
                        case 'nonUser' : setConfirm(true);loadingOff();return (
                            setErrorMessage({
                                title: ``,
                                subtitle: <><span style={{display:"inline-block",color:"#000"}}>해당 연락처로 가입된 이력이 없습니다.</span></>
                            }),
                                setErrorMsg(true)
                        );break;
                    }
                })
        };
    }

    const onChangeInput = ()=>{
        let x = document.getElementById("inputPhoneNumber");
        let val = x.value.trim();
        let result = autoHypenTel(val);

        console.log(result);
        let y = document.getElementById("phoneResult");
        y.innerText = result;
    }

    // 전화번호 자동 파싱
    function autoHypenTel(str) {
        str = str.replace(/[^0-9]/g, '');
        let tmp = '';

        if (str.substring(0, 2) == '02') {
            // 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
            if (str.length < 3) {
                return str;
            } else if (str.length < 6) {
                tmp += str.substr(0, 2);
                tmp += '-';
                tmp += str.substr(2);
                return tmp;
            } else if (str.length < 10) {
                tmp += str.substr(0, 2);
                tmp += '-';
                tmp += str.substr(2, 3);
                tmp += '-';
                tmp += str.substr(5);
                return tmp;
            } else {
                tmp += str.substr(0, 2);
                tmp += '-';
                tmp += str.substr(2, 4);
                tmp += '-';
                tmp += str.substr(6, 4);
                return tmp;
            }
        } else {
            // 핸드폰 및 다른 지역 전화번호 일 경우
            if (str.length < 4) {
                return str;
            } else if (str.length < 7) {
                tmp += str.substr(0, 3);
                tmp += '-';
                tmp += str.substr(3);
                return tmp;
            } else if (str.length < 11) {
                tmp += str.substr(0, 3);
                tmp += '-';
                tmp += str.substr(3, 3);
                tmp += '-';
                tmp += str.substr(6);
                return tmp;
            } else {
                tmp += str.substr(0, 3);
                tmp += '-';
                tmp += str.substr(3, 4);
                tmp += '-';
                tmp += str.substr(7);
                return tmp;
            }
        }
        return str;
    }

// 조회 or 신청 버튼 매칭 event
    const modalBtMatch = () => {
        if(!confirm){
            return (
                <>
                    <Button className="applyModalBtn" key="back" onClick={cancel}>
                        취ㅤ소
                    </Button>
                    <Button
                        className="applyModalBtn"
                        // onClick={()=>{
                        //     setUserExistCheckModal(false);
                        // }}
                        htmlType="submit"
                        style={{backgroundColor:"#FFD560", marginLeft:20}}
                        disabled={SearchLoading}
                        type="primary" loading={SearchLoading}
                    >
                        조ㅤ회
                    </Button>
                </>
            )
        }else if (confirm == 'onlineUser'){
            return (
                <>
                    <Button className="applyModalBtn" key="back" onClick={cancel}>
                        취ㅤ소
                    </Button>
                    <Button
                        className="applyModalBtn"
                        onClick={toLogin}
                        style={{backgroundColor:"#FFD560", marginLeft:20}}
                        disabled={SearchLoading}
                        type="primary" loading={SearchLoading}>
                        로그인 이동
                    </Button>
                </>
            )
        }else if (confirm == 'offlineUser'){
            return (
                <>
                    <Button className="applyModalBtn" key="back" onClick={cancel}>
                        취ㅤ소
                    </Button>
                    <Button
                        className="applyModalBtn"
                        onClick={toHome}
                        style={{backgroundColor:"#FFD560", marginLeft:20}}
                        disabled={false}
                        type="primary" loading={SearchLoading}>
                        홈으로 이동
                    </Button>
                </>
            )
        }else{
            return (
                <>
                    <Button className="applyModalBtn" key="back" onClick={cancel}>
                        취ㅤ소
                    </Button>
                    <Button
                        className="applyModalBtn"
                        onClick={()=>{
                            setUserExistCheckModal(false);
                        }}
                        style={{backgroundColor:"#FFD560", marginLeft:20}}
                        disabled={SearchLoading}
                        type="primary" loading={SearchLoading}>
                        회원가입 계속
                    </Button>
                </>
            )
        }
    }
    return (
        <>
            <Modal
                visible={userExistCheckModal}
                footer={null}
                wrapClassName="userExistCheck"
            >
                <Title level={3} style={{textAlign: 'center', margin:'15px auto'}}>{ !confirm ? '가입 여부 조회' : '회원가입' }</Title>
                <Row className="add-table-row" justify="center" align="middle">
                    <Row style={{maxWidth:"90%", margin: "25px auto", minWidth: "200px", position:"relative" }}>
                        <Form
                            form={form}
                            onFinish={onUserSubmit}
                        >
                            {/*<Col className="useraddModalCol" span={24}>
                                <Form.Item
                                    label="이름"
                                    name="userNameReal"
                                    className="offuserformItem"
                                    rules={[{ required: true, message: '이름을 입력하지 않았습니다.'}]}
                                >
                                    <Input
                                        value={state.userNameReal}
                                        disabled={confirm}
                                        type="text"
                                        size="large"
                                        placeholder="이름을 입력해 주세요." />
                                </Form.Item>
                            </Col>*/}
                            <Col>
                                <span style={{}} id="phoneResult"></span>
                            </Col>
                            <Col className="useraddModalCol" span={24} style={{paddingBottom:'35px'}}>
                                <Form.Item
                                    label="연락처"
                                    name="phoneNumber"
                                    className="offuserformItem"
                                    rules={[
                                        { pattern:'[0-9]{3}[0-9]{3,4}[0-9]{4}', message:'(-)를 제외한 연락처를 입력해 주세요.'},
                                        { required: true, message: '연락처를 입력해 주세요.' }]}
                                >
                                    <Input
                                        value={state.phoneNumber}
                                        type="tel"
                                        disabled={confirm}
                                        size="large"
                                        maxLength={11}
                                        onChange={onChangeInput}
                                        id="inputPhoneNumber"
                                        placeholder="연락처를 정확히 입력해 주세요." />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{textAlign:"center", margin:0, position:"absolute", bottom: "75px", right:'0', width:'100%'}}>
                                { errMsg ?
                                    <>
                                        <Text type="danger" style={{fontWeight:900 }}>{ettMessage?.title}</Text><br />
                                        <Text type="secondary" style={{fontWeight:600 }}>{ettMessage?.subtitle}</Text>
                                    </>
                                    : null }

                            </Col>
                            {/*<Col span={24} style={{textAlign: "center", margin:0, position:"absolute", bottom: "85px", right: "25%"}}>*/}
                            {/*    { confirm ?*/}
                            {/*        <Text type="success" style={{fontWeight:900 }}>해당 연락처로 가입된 이력이 없습니다.</Text>*/}
                            {/*        : null*/}
                            {/*    }*/}
                            {/*</Col>*/}
                            <Form.Item style={{margin:"20px 0"}}>
                                { modalBtMatch() }
                                <Tooltip title="재조회">
                                    <Button icon={<ReloadOutlined />} className="reloadSearchBtn" onClick={reload} size={"default"} />
                                </Tooltip>
                            </Form.Item>
                        </Form>
                    </Row>
                </Row>
            </Modal>
        </>
    )
};

export default UserExistCheck;
