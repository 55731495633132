import React from 'react';
import img from "../../../assets/img/vision.jpg";

function OranizationChart() {
    return(
        <div id="OranizationChart" className='static container'>
            <div className="OranizationChart-img"><img src={img} alt="" /></div>
                <ul className='CharWrapper'>
                    <li className='Chart'>
                        <p >원장</p>
                    </li>
                    <li className='Chart'><p>부원장</p></li>
                    <div className='Chart3'>
                        <li className='Chart'><p>고문</p></li>
                        <li className='Chart'><p>운영위원</p></li>
                        <p className='onlyMobile'/>{/* slider */}
                        <li className='Chart'><p>이사</p></li>
                        <li className='Chart'><p>감사</p></li>
                    </div>
                    <li className='Chart'><p>사무국장</p></li>
                    <div className='Chartdiv'>
                        <li className='Chart'><p>운영부장</p></li>

                        <li className='Chart'><p>연구부장</p></li>
                    </div>
                    <div>
                        <li className='Chart'><p>문화교육사업팀</p>
                            <ul>
                                <li>문화예술교육사업</li>
                                <li>문화강좌 및 문화사업 지원</li>
                                <li>각종 사업 예산 지출</li>
                            </ul>
                        </li>
                        <li className='Chart'><p>문화기획팀</p>
                            <ul>
                                <li>문화행사 기획 및 진행</li>
                                <li>역사문화자원 콘텐츠 디자인</li>
                                <li>홍보 일반</li>
                            </ul>
                        </li>
                        <p className='onlyMobile'/>{/* slider */}
                        <li className='Chart'><p>성북학아카이브팀</p>
                            <ul>
                                <li>성북학 자원 발굴 및 아카이빙</li>
                                <li>성북학 학술회의 및 발간 사업</li>
                                <li>역사문화인물 기념 문화사업</li>
                            </ul>
                        </li>
                        <li className='Chart'><p>성북마을아카이브팀</p>
                            <ul>
                                <li>성북마을아카이브 관리 및 활용사업</li>
                                <li>주민기록단 양성 교육</li>
                                <li>종암동 수장고 운영</li>
                            </ul>
                        </li>
                    </div>
                </ul>
        </div>
    )
}
export default OranizationChart;
