/*global kakao */
import React, { useEffect } from "react";

export default function KaKaoMap() {
  useEffect(() => {
    mapscript();
  }, []);

  const mapscript = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
        // "https://dapi.kakao.com/v2/maps/sdk.js?appkey=본인 API키값&libraries=LIBRARY";
        "https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=706ab543d1b32b564c89b43b6dfab6ed";   // autoload=false가 들어가야 비동기 통신으로 페이지가 로딩될경우 로딩전 객체 접근을 막아줌
    document.getElementsByTagName('head')[0].appendChild(script);

    script.onload = () => {
      kakao.maps.load(() => {
        let container = document.getElementById("map");// eslint-disable-line no-unused-vars
        let options = {
          center: new kakao.maps.LatLng(37.589673525381386, 127.00574265252824),
          level: 5,
        };
        //map
        const map = new kakao.maps.Map(container, options);

        //마커가 표시 될 위치
        let markerPosition = new kakao.maps.LatLng(37.589673525381386, 127.00574265252824);

        // 마커를 생성
        let marker = new kakao.maps.Marker({
          position: markerPosition,
        });

        // 마커를 지도 위에 표시
        marker.setMap(map);
      });
    };

  };

  return <div id="map" style={{height: "600px", maxWidth: '100%'}}/>;
}
