import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { Modal, Button, Row, Col, Form, Input, Result, Typography, Checkbox, message } from 'antd';
import {EditOutlined} from '@ant-design/icons';
import { defaultApiAddress, refreshToken } from '../../services/api.helpers';

const { Title, Text } = Typography;

const ApplyBtn = (props) => {

  const { history, userObj, detailData, setUserObjFn } = props;
  
  const [ applyLoading, setApplyLoading] = useState(false);
  const [ applyModal, setApplyModal] = useState(false);
  const [ errorModal, setErrorModal] = useState(false);
  const [ errMsg, setErrorMsg ] = useState(null);
  const [ noticeModal, setNoticeModal] = useState(false);
  const [ noticeMsg, setNoticeMsg ] = useState(null);
  const [ successModal, setSuccessModal] = useState(false);
  const [ reqLoginModal, setReqLoginModal ] = useState(false);
  const [ applyStatus, setApplyStatus] = useState(null);
  const [ applyData, setApplyData] = useState({
      userNameReal: '',
      phoneNumber: ''
  });
  const [localToken, setLocalToken] = useState(null);

  const courseId = detailData.id;
  const userId = userObj ? userObj.uid : '';
    const [applyform] = Form.useForm();

    useEffect(()=>{
        refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
    },[]);

    function onChange(value) {
        console.log(value)
    }

      const onApply = async () => {
        if (userObj == null) {
            // message.error('로그인 후 이용해 주세요.');setApplyModal(false);
            setReqLoginModal(true);
            return false;
        }
          await setApplyModal(true);

          await axios.get(`${defaultApiAddress}/users/${userId}`, {headers: {
                  "Authorization" : localToken,
                  "Content-Type" : "application/json",
                  "Access-Control-Allow-Origin" : "*",
              }})
              .then(async response => {
                  await setApplyData({userNameReal: response.data.userNameReal, phoneNumber: response.data.phoneNumber});

                  await applyform.setFieldsValue({
                      "userNameReal": response.data.userNameReal,
                      "phoneNumber": response.data.phoneNumber
                  });
              }).catch(function (error) {
                      if(error.response){
                          let err_msg = error.response.data;
                          setErrorMsg(err_msg.message);
                          errorResult();
                      }
              });
      }

      const handleCancel = (e) => {
        e.preventDefault();
        if(applyModal === true){ 
          setApplyModal(false)
        }else{
          setErrorModal(false)
          setNoticeModal(false)
        }
      };

    const handleFail = () => {
        setReqLoginModal(false);
        history.push('/signin')
    }

    const noticeClose = (e) => {
        e.preventDefault();

        setUserObjFn(()=>{
            let _userObj = {...userObj};
            _userObj.displayName = userObj.displayName;
            _userObj.email = userObj.email;
            _userObj.idToken = userObj.idToken;
            _userObj.photoURL = userObj.photoURL;
            _userObj.provider = userObj.provider;
            _userObj.uid = userObj.uid;
            _userObj.updateProfile = userObj.updateProfile;
            _userObj.userNameReal = applyData.userNameReal || userObj.userNameReal;
            _userObj.phoneNumber = applyData.phoneNumber || userObj.phoneNumber;
            return _userObj;
        });
            if(applyModal === true){
            setApplyModal(false)
        }else{
            setErrorModal(false)
            setNoticeModal(false)
        }
    };

      const handleOk = () => {
        setApplyLoading(true);
            setTimeout(() => {
            if(applyModal === true){
                setApplyModal(false);
              }
              setApplyLoading(false);
              setSuccessModal(true);
            }, 1000);
          setTimeout(() => {
            setSuccessModal(false);
          }, 10000);
      };
    
      const results = () => {
          setApplyLoading(true);
            setTimeout(() => {
              if(applyModal === true){
                setApplyModal(false);
              }
              if(applyStatus == "error"){
                setApplyLoading(false);
                setErrorModal(true);
              }else{
                setApplyLoading(false);
                setSuccessModal(true);
              }
          }, 2000);
      }

      const errorResult = () => {
        setApplyLoading(true);
        setTimeout(() => {
          if(applyModal === true){
              setApplyModal(false);
            }
            setApplyLoading(false);
            setErrorModal(true);
          }, 1000);
      }

      const noticeResult = () => {
        setApplyLoading(true);
        setTimeout(() => {
          if(applyModal === true){
              setApplyModal(false);
            }
            setApplyLoading(false);
            setNoticeModal(true);
          }, 1000);
      }

      const onApplySubmit = async (e) => {
        await setApplyData(e);
        const _applyData = e;   // state 변화는 시간차로 나서 일단 임시 변수 선언해서 데이터 전달
          // const _applyData = applyData; // onChange로 일단 applyData에 실시간 매핑
        if (userObj == null) {
            setErrorMsg('유저 정보를 찾을 수 없습니다! 다시 로그인 해주세요');
            setErrorModal(true);
            // message.error('유저 정보를 찾을 수 없습니다! 다시 로그인 해주세요.');
            return false
        }

          axios.put(`${defaultApiAddress}/users/${userId}`, _applyData, {headers: {
                  "Authorization" : localToken,
                  "Content-Type" : "application/json",
                  "Access-Control-Allow-Origin" : "*",
              }})
                  .then(response => {
                      applyform.setFieldsValue(response.data.id);

                      // 유저정보
                      const _userInfo = {
                          userNameReal: response.data.userNameReal ? response.data.userNameReal : '',
                          phoneNumber: response.data.phoneNumber ? response.data.phoneNumber : ''
                      };
                      axios.post(`${defaultApiAddress}/courses/${courseId}/entry`, applyData, {headers: {
                              "Authorization" : localToken,
                              "Content-Type" : "application/json",
                              "Access-Control-Allow-Origin" : "*",
                          }})
                          .then(async function (res){
                              handleOk();
                          })
                          .catch(function (error) {
                              if(error.response){
                                  let err_msg = error.response.data;
                                  if (err_msg?.errorCode === 'ENTRY_THIS_COURSE') {
                                      setNoticeMsg('이미 수강신청한 과목입니다!');
                                  } else {
                                      setNoticeMsg(err_msg.message);
                                  }
                                  noticeResult();
                              }
                          })
                  }).catch(function (error) {
                      if(error.response){
                          let err_msg = error.response.data;
                          setErrorMsg(err_msg.message);
                          errorResult();
                      }
          })

      }

      const ApplyFinishFaild = (errorInfo) => {
          console.log(errorInfo);
      }

      const onSuccess = () => {
          setUserObjFn(()=>{
              let _userObj = {...userObj};
              _userObj.displayName = userObj.displayName;
              _userObj.email = userObj.email;
              _userObj.idToken = userObj.idToken;
              _userObj.photoURL = userObj.photoURL;
              _userObj.provider = userObj.provider;
              _userObj.uid = userObj.uid;
              _userObj.updateProfile = userObj.updateProfile;
              _userObj.userNameReal = applyData.userNameReal || userObj.userNameReal;
              _userObj.phoneNumber = applyData.phoneNumber || userObj.phoneNumber;
              return _userObj;
          });

        history.push('/program/program-all')
      }

  return (
    <>
       <Button className="btnApply" type="primary" onClick={onApply}>강좌신청</Button>
       <Modal
          visible={applyModal}
          className="applyModal"
          width="100%"
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <p><EditOutlined style={{fontSize:20}} /></p>
          <Title level={3}>강좌신청</Title>
          <Row>
            <Form
              labelCol={{ span: 6 }}
              name="applyForm"
              form={applyform}
              layout="vertical"
              className="applyForm"
              onFinish={onApplySubmit}
              onFinishFailed={ApplyFinishFaild}
              >
                <Row style={{maxWidth:"90%", margin: "25px auto", minWidth: "200px"}}>
                  <Form.Item
                    label="이름"
                    name="userNameReal"
                    rules={[{ required: true, message: '이름을 입력하지 않았습니다.'}]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="연락처"
                    name="phoneNumber"
                    rules={[{ required: true, message: '연락처를 입력하지 않았습니다.'}]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="입금계좌"
                    style={{margin:"10px 0 20px"}}
                    >
                    <div style={{border: "1px solid #E0E0E0", padding: "24px 0"}}>
                      <Title style={{marginBottom:0}} level={4}><img src="/image/bank.png" />ㅤ우리은행 1005-201-270900</Title>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="turmChkd"
                    defaultValue={false}
                    valuePropName="checked"
                    // style={{ textAlign:"left"}}
                    rules={[{ required: true, message: '* 정보 제공에 동의해야 신청이 완료됩니다.'}]}
                  >
                    <Checkbox>
                      <span style={{color: "#333333", fontSize:14, fontWeight:"bold"}}>정보 제공에 동의합니다.<span style={{color: "#666666", fontSize:12, fontWeight:500}}> (수집된 정보는 강의 목적 용도로만 사용됩니다.)</span></span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item style={{margin:"50px 0 20px"}}>
                    <Button className="applyModalBtn" key="back" onClick={handleCancel}>
                      취ㅤ소
                    </Button>
                    <Button className="applyModalBtn" key="submit" htmlType="submit" style={{backgroundColor:"#FFD560", marginLeft:20}}
                            type="primary" loading={applyLoading}>
                      강좌신청
                    </Button>
                  </Form.Item>
                </Row>

            </Form>
            
          </Row>
        </Modal>
       <Modal
          visible={errorModal}
          className="applyModal"
          width="100%"
          footer={null}>
        <Result
            status="error"
            title="수강신청에 실패하였습니다."
            subTitle={errMsg}
            extra={[
              <Button className="btnApply" type="primary" key="확인" onClick={handleCancel}>
                확인
              </Button>,
            ]}
          />
       </Modal>
        <Modal
            visible={reqLoginModal}
            className="applyModal"
            width="100%"
            footer={null}>
            <Result
                status="error"
                title="로그인 후 수강신청 가능합니다."
                subTitle="로그인 후 다시 시도해 주세요."
                extra={[
                    <Button className="btnApply" type="primary" key="확인" onClick={handleFail}>
                        확인
                    </Button>,
                ]}
            />
        </Modal>
        <Modal
            visible={noticeModal}
            className="applyModal"
            width="100%"
            footer={null}>
            <Result
                status="warning"
                title={noticeMsg}
                // subTitle={noticeMsg}
                extra={[
                    <Button className="btnApply" type="primary" key="확인" onClick={noticeClose}>
                        확인
                    </Button>,
                ]}
            />
        </Modal>
       <Modal
          visible={successModal}
          className="applyModal"
          width="100%"
          footer={null}>
       <Result
            status="success"
            title="강좌 신청에 성공하였습니다."
            subTitle="수강 인원은 입금 확인순으로 선착순 마감됩니다."
            extra={[
              <Button className="btnApply" type="primary" key="console" onClick={onSuccess}>
                확인
              </Button>,
            ]}
          />
       </Modal>
   
    </>
  );
}

export default ApplyBtn;
