import React from 'react';
import sbccHistory from '../../../assets/img/logo_500x500.png';
import line from '../../../assets/img/line.png';

function History() {
        return(
			<div id='Milestones' className="static container">
                <div className='HistoryList'>
                    <img alt="HISTORY 성북문화원" src={sbccHistory} style={{margin:'0 auto'}}/>
                        <div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul last'>
								<div className='Listli year'>2023</div>
								<div className='Listli'><div className='Date'>03.</div><div className='HistoryTit'>제4대 김영일 원장 취임</div></div>
								<div className='Listli'><div className='Date'>06.</div><div className='HistoryTit'>제2회 민간기록물 공모전 '서랍 속 학교 이야기' 개최</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>제6회 성북학 학술회의 '경계를 넘어 : 지역학의 교류와 성장' 개최</div></div>
								<div className='Listli'><div className='Date'>11.</div><div className='HistoryTit'>성북 주민아카데미 '영화 드라마 속 역사 이야기' 개최</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북문화원-성신여대 업무협약 체결</div></div>
							</div>
                        </div>
                        <div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2022</div>
								<div className='Listli'><div className='Date'>01.</div><div className='HistoryTit'>‘2021 자치분권어워드’ 동상 수상</div></div>
								<div className='Listli'><div className='Date'>03.</div><div className='HistoryTit'>‘2022 거버넌스 지방정치대상 공모대회’ 우수상 수상</div></div>
								<div className='Listli'><div className='Date'>08.</div><div className='HistoryTit'>성북마을아카이브 ‘성북구 주민기록단’ 공식 출범</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>‘2022 성북구 문화관광해설사 신규양성과정’ 교육 진행</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>제4회 성북학 학술회의 '지역학의 현황과 과제' 개최</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>성북문화원-성북마을미디어네트워크 마을기록 수집·보존을 위한 업무협약 체결</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북 사람들의 구술생애사 시리즈 6~7 「지역의 변화와 삶을 말하다」 「활량리 터줏대감」 발행</div></div>
							</div>
                        </div>
                        <div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2021</div>
								<div className='Listli'><div className='Date'>02.</div><div className='HistoryTit'>국가기록관리 유공 행정안전부 장관 표창 수상</div></div>
								<div className='Listli'><div className='Date'>08.</div><div className='HistoryTit'>‘2021 우수행정 및 정책사례 선발대회’ 우수상 수상</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>제1회 성북구 민간기록물 수집 공모전 개최</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>제1회 마을기록전시 ‘우리의 기억, 성북의 기록으로’</div></div>
								<div className='Listli'><div className='Date'>11.</div><div className='HistoryTit'>서울 동북권의 역사문화적 특성과 지역학 연구의 과제</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북 사람들의 구술생애사 시리즈 4~5 「나의 살던 북정마을」, 「온기를 배달해 드립니다」 발행</div></div>
							</div>
                        </div>
                        <div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2020</div>
								<div className='Listli'><div className='Date'>09.</div><div className='HistoryTit'>인문학 다방, 주민 아카데미 등 온라인 인문학 강좌 제작</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>국가기록관리 유공 행정안전부 장관 표창 수상</div></div>
							</div>
                        </div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2019</div>
								<div className='Listli'><div className='Date'>02.</div><div className='HistoryTit'>여성독립운동가열전① 공연</div></div>
								<div className='Listli'><div className='Date'>05.</div><div className='HistoryTit'>한성대 캠퍼스타운 축제 '삼선유람'</div></div>
								<div className='Listli'><div className='Date'>09.</div><div className='HistoryTit'>성북마을아카이브 '성북마을발견×문학' 개설</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북마을아카이브 홈페이지 개설</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>독립운동가 후손 구술자료집 '이야기 하다 보면 생각이 나'출간</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2018</div>
								<div className='Listli'><div className='Date'>01.</div><div className='HistoryTit'>마을기록아카이브 구축 사업</div></div>
								<div className='Listli'><div className='Date'>05.</div><div className='HistoryTit'>성북동 문학주간 연속강연 ‘여담’</div></div>
								<div className='Listli'><div className='Date'>07.</div><div className='HistoryTit'>정전협정 65주년 기념 학술회의 ‘정전에서 종전으로 그리고 평화로’</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>고려대 캠퍼스타운 축제 끌어안암</div></div>
								<div className='Listli'><div className='Date'>11.</div><div className='HistoryTit'>대학생 주거공연 예술 프로젝트 ‘동거’</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2017</div>
								<div className='Listli'><div className='Date'>05.</div><div className='HistoryTit'>성북동 문화재야행(봄/가을)</div></div>
								<div className='Listli'><div className='Date'>06.</div><div className='HistoryTit'>채동선 탄생 117주년 기념행사</div></div>
								<div className='Listli'><div className='Date'>09.</div><div className='HistoryTit'>윤이상 탄생 100주년 기념 시민음악회</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>나운규 서거 80주년 기념영화제</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>지방문화원 향토사연구실적 특별상 수상</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2016</div>
								<div className='Listli'><div className='Date'>04.</div><div className='HistoryTit'>4.19 혁명 시위재현행사</div></div>
								<div className='Listli'><div className='Date'>05.</div><div className='HistoryTit'>이육사 탄생 112주년 기념 문화재 개최 (매년)</div></div>
								<div className='Listli'><div className='Date'>06.</div><div className='HistoryTit'>청록집 발간 70주년, 문학 주간 행사</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>한국문화원연합회 대한민국문화원상 종합경영부문 우수상, <br className="onlyPC"/>향토사 활용부문 1위상 수상, 창립 20주년 기념행사</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2015</div>
								<div className='Listli'><div className='Date'>01-02.</div><div className='HistoryTit'>「시민강좌 성북, 문화다양성의 뿌리를 찾아서」</div></div>
								<div className='Listli'><div className='Date'>04.</div><div className='HistoryTit'>4.19 혁명 55주년 기념 공연이 있는 학술회의 개최(매년)</div></div>
								<div className='Listli'><div className='Date'>04-12.</div><div className='HistoryTit'>문화재지역 주민공감정책사업</div></div>
								<div className='Listli'><div className='Date'>05-08.</div><div className='HistoryTit'>창조적 문화융성을 위한 한국형 복합문화공간 설립 방안 연구</div></div>
								<div className='Listli'><div className='Date'>06.</div><div className='HistoryTit'>광복 70주년 기념 성북구 평화통일 예술제</div></div>
								<div className='Listli'><div className='Date'>06-12.</div><div className='HistoryTit'>마을관광상품 개발·운영 「서울 속 마을여행」<br/> (성북동 영상 제작)</div></div>
								<div className='Listli'><div className='Date'>11.</div><div className='HistoryTit'>6.25 전쟁과 미아리고개 학술회의</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북구역사문화산책 제3호 「성북동」 발행</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>「문화재의 숲, 사람의 마을」, 「성북동 이야기 자원 모음집」 발행</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line' alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2014</div>
								<div className='Listli'><div className='Date'>03.</div><div className='HistoryTit'>성북문화 아카데미 (뮤지컬 심우 제작, 공연)</div></div>
								<div className='Listli'><div className='Date'>04-12.</div><div className='HistoryTit'>청소년 지역 문화 창조 프로그램 「앞으로 빛날 나날」(뮤지컬 시간을 달리는 소녀 제작, 공연)</div></div>
								<div className='Listli'><div className='Date'>05-12.</div><div className='HistoryTit'>마을관광상품 개발ㆍ운영 「서울 속 마을여행」 (거리극 특별한 손님 제작, 공연)</div></div>
								<div className='Listli'><div className='Date'>05-12.</div><div className='HistoryTit'>문화재지역 주민공감정책사업</div></div>
								<div className='Listli'><div className='Date'>11.</div><div className='HistoryTit'>잡지 「성북문예」 제2호 발행</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북구 답사여행 길잡이 「성북에서 만나는 자연과 역사와 사람들」 발행</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북구역사문화산책 제2호 「미아리고개」 발행</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>「한양도성 역사산책」, 「한양도성 이야기자원 모음집」, 「니들이 한양도성을 알아」 발행</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line' alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2013</div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>잡지 「성북문예」 창간호 발행</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>잡지 「성북문화」 창간호 발행(매년)</div></div>
								<div className='Listli'><div className='Date'>12.</div><div className='HistoryTit'>성북구역사문화산책 제1호 「동소문 밖 능말이야기」, 「스토리텔링 리소스 북」 발행</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2011</div>
								<div className='Listli'><div className='Date'>03.</div><div className='HistoryTit'>제3대 조태권 원장 취임</div></div>
								<div className='Listli'><div className='Date'>11.</div><div className='HistoryTit'>지역여성문화콘텐츠 발굴사업 '우리동네에는 선잠단이 있어요' CD제작 및 소책자 발간</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line' alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2010</div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>지역여성문화콘텐츠 발굴사업 '선잠단과 길쌈이야기' 출간</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2009</div>
								<div className='Listli'><div className='Date'>06.</div><div className='HistoryTit'>전국성북사진공모전 개최(매년)</div></div>
								<div className='Listli'><div className='Date'>10.</div><div className='HistoryTit'>성북문화가족 어울마당 축제 개최(매년)</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line' alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2008</div>
								<div className='Listli'><div className='Date'>05.</div><div className='HistoryTit'>선잠제향·다문화축제 개최(매년)</div></div>
									<div className='Listli'><div className='Date'>06.27</div><div className='HistoryTit'>만해 한용운 선사 64주기 추모 다례재(매년)</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line' alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>2005</div>
								<div className='Listli'><div className='Date'>03.</div><div className='HistoryTit'>제2대 천신일 원장 취임</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>1997</div>
								<div className='Listli'>
									<div className='Date'>01</div>
									<div className='HistoryTit'>제1대 구두회 원장 취임</div>
								</div>
								<div className='Listli'>
									<div className='Date'>01.15</div>
									<div className='HistoryTit'>비영리법인 설립등기 (등기번호: 제 483호)</div>
								</div>
								<div className='Listli'>
									<div className='Date'>01.23</div>
									<div className='HistoryTit'>사업자등록 (고유번호등록)</div>
								</div>
							</div>
						</div>
	                <div className='List'>
		                <img className='Line' alt="line" src={line}/>
		                <div className='Listul'>
								<div className='Listli year'>1996</div>
								<div className='Listli'><div className='Date'>10.07</div><div className='HistoryTit'>창립총회 회의(성북구청장 방침 제 1595호)</div></div>
								<div className='Listli'><div className='Date'>12.02</div><div className='HistoryTit'>개원 지원 계획(성북구청장 방침 제 1968호)</div></div>
								<div className='Listli'><div className='Date'>12.30</div><div className='HistoryTit'>성북문화원 인가 (인가번호: 제 424호)</div></div>
							</div>
						</div>
						<div className='List'>
							<img className='Line'  alt="line" src={line}/>
							<div className='Listul'>
								<div className='Listli year'>1995</div>
								<div className='Listli'><div className='Date'>07.12</div><div className='HistoryTit'>성북문화원 설립(지원·육성)계획 <br/>(성북구청장 방침 제1477호)</div></div>
							</div>
						</div>
						<div className="last">&nbsp;</div>
				</div>
			</div>
        )
    }

export default History;
