import React, { useState, useEffect } from 'react';
import { Modal, Button, Result } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import {createBrowserHistory} from "history";

const ModalBtnNotice = (props) => {
    const { modalData, setModalDataClose } = props;
    const history = useHistory();

    const [_modalData, _setModalData] = useState({
        isModalVisible: false
    });

    const getModal = async ()=>{
        // await console.log('변함!',modalData);
        // await console.log('변함1!',_modalData);
        await _setModalData(modalData);
    }

    useEffect( ()=>{
        getModal().then(r => {
            // console.log('변함2!',_modalData);
        });
    },[modalData]);

    const removeModal = () => {
        setModalDataClose();
    };

    const redirect = (url) => {
        removeModal();
        if (!!url) {      //url이 없이 들어오는건 확인 눌러도 꺼지도록
            history.push(url);
            console.log('강제 새로고침');
            createBrowserHistory({
                forceRefresh: true
            });
        }
    }

    // setTwoToneColor('#F55F4A');
    // getTwoToneColor();

    return (
        <>
            <Modal
                visible={_modalData.isModalVisible}
                footer={null}
            >
                <Result
                    // icon={<DownCircleOutlined />}
                    status="success"
                    title="확인"
                    subTitle={_modalData?.modalMessage}
                    extra={
                        <Button type="primary" onClick={()=>{redirect(_modalData?.modalUrl)}}>
                            확인
                        </Button>
                    }
                />
            </Modal>
        </>
    );
}

export default ModalBtnNotice;
