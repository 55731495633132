import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from 'moment';
import { Space, Table, Spin, Pagination, Tag } from 'antd';
import { defaultApiAddress } from '../../services/api.helpers';
// import BoardSearch from "./boardBasic/BoardSearch";

export default function BasicList(props) {

    const { history, type, cate, init, userObj } = props;
    const getPathName =  history.location.pathname;
    const [ data, setData ] = useState(null);
    const [ page, setPage ] = useState(0);
    const [ totalCnt, setTotalCnt ] = useState(0);

    // let newDate = moment(i?.createdAt).startOf('day').fromNow();
    // console.log(newDate);

    const columns = [
        {
            title: '번호',
            width:'10%',
            dataIndex:'listNo',
            align: 'center',
            className:"noTxt",
            key:'listNo',
            render: number => ((parseInt(totalCnt/12) > page) ? totalCnt - 12*(page+1) + number : number),
        },
        {
            title: '',
            width:'6%',
            dataIndex:'',
            align: 'center',
            className:"newTag",
            key:'new',
            render: item => {
                const sevenDays = moment(item?.createdAt).format('YYYY/MM/DD');
                const today = moment().subtract(7, 'days');
                if(moment(sevenDays).isAfter(today) == true){
                    return <Tag color="orange">NEW</Tag>;
                }else{
                    return null
                }
            }
        },
        {
            title: '제목',
            dataIndex:'title',
            width:'60%',
            key:'title',
            className:"titleTxt",
            render: text => <a style={{color: '#000000'}}>{text}</a>,
        },
        {
            title: '작성자',
            dataIndex:'writer',
            width:"20%",
            key:'writer',
            align: 'center',
            value:'성북문화원',
            className:"writerTxt",
        },
        {
            title: '날짜',
            dataIndex:'createdAt',
            render: item => moment(item).format('YYYY/MM/DD'),
            width:"20%",
            align: 'center',
            key:'createdAt',
            className:"grayTxt",
        },
    ];

    async function fetchData() {
        // setData(null);
        const result = await axios.get(`${defaultApiAddress}/boards?type=${type}&cate=${cate}&rpp=12&page=${page}`);

        setData(result?.data.results);
        setTotalCnt(result?.data?.totalCount);
        console.log(result.data);

        const resultdata = result.data.results;
        let newData = [];

        const reverseArray = resultdata.reverse();
        console.log(reverseArray);

        reverseArray.map((i, index) => {
            let listData;
            return listData = Object.assign({...i, 'listNo': index + 1}),
                newData.push(listData)
        })
        const dataSort = newData.reverse();

        if(dataSort !== null){
            setData(dataSort);
        }
    }

// 최초 렌더링 시 발생할 effect
    useEffect(() => {
        if (init == 'notLogined' || (!!init && !!userObj)) {
            fetchData();
        }
        return () => {setData(null)}
    }, [page]);

    const onChange = (current) => {
        let crr = (current-1);
        console.log(crr);
        return (
            setPage(crr)
        );
    }

    const onRow = (rowData, rowIndex) => {
        return {
            onClick: () => {
                history.push({
                    pathname: `${getPathName}/detail/${rowData.id}`,
                });
            }
        }
    }

    return(
        <div>
            <div className="List" style={{ maxWidth:"1200px", margin:"-5px auto", padding:"0 24px 24px"}}>
                <Space align="end" size="small" direction="vertical">
                    <Table
                        tableLayout="fixed"
                        expandRowByClick={true}
                        columns={columns}
                        dataSource={data}
                        onRow={onRow}
                        rowKey={totalCnt}
                        pagination={{
                            // defaultCurrent:1,
                            current: page + 1,
                            pageSize: 12,
                            total: totalCnt,
                            onChange: onChange,
                            size: 'small',
                            showSizeChanger: false,
                            showQuickJumper: false
                        }}
                    />
                </Space>
            </div>
        </div>
    );
}
