import React from "react";
// site logo img
import logo1 from "../../../assets/img/seoulLogo.jpg";
import logo2 from "../../../assets/img/sbLogo.jpg";
import logo3 from "../../../assets/img/nCultureLogo.jpg";
import logo4 from "../../../assets/img/mscstLogo.jpg";
import logo5 from "../../../assets/img/kccfLogo.jpg";
import logo6 from "../../../assets/img/seoulccfLogo.jpg";
import logo7 from "../../../assets/img/sbnightroadLogo.jpg";
import logo8 from "../../../assets/img/sbarchiveLogo.jpg";
import logo9 from "../../../assets/img/sbflagartLogo.jpg";
import logo10 from "../../../assets/img/natTaxSvcLogo.jpg";

import { Card, Col, Row } from 'antd';

function Familysite(){
  return(
    <div id="SiteData" className='static container'>
      <h2><strong>FAMILY SITE</strong></h2>
      <p>기관명을 누르면 해당 사이트로 이동합니다</p>
      <div className="site-card-wrapper">
        <Row gutter={8}>
          <Col>
            <a href="https://www.seoul.go.kr" target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="서울특별시" src={logo1}/>}>
              서울특별시
              </Card>
            </a>
          </Col>
          <Col>
          <a href='https://www.sb.go.kr' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="성북구청" src={logo2}/>}>
              성북구청
              </Card>
            </a>
          </Col>
          <Col>
          <a href='https://www.nculture.org' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="지역N문화" src={logo3}/>}>
              지역N문화
              </Card>
            </a>
          </Col>
          <div className='clear onlyPC'/>
          <Col>
          <a href='https://www.mcst.go.kr' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="문화체육관광부" src={logo4}/>}>
              문화체육관광부
              </Card>
            </a>
          </Col>
          <Col>
          <a href='https://www.kccf.or.kr' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="한국문화원연합회" src={logo5}/>}>
              한국문화원연합회
              </Card>
            </a>
          </Col>
          <Col>
          <a href='http://seoulccf.or.kr' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="서울시문화원연합회" src={logo6}/>}>
              서울시문화원연합회
              </Card>
            </a>
          </Col>
          <div className='clear onlyPC'/>
          <Col>
          <a href='https://www.sbnightroad.com/' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="성북동밤마실" src={logo7}/>}>
              성북동밤마실
              </Card>
            </a>
          </Col>
          <Col>
          <a href='https://archive.sb.go.kr' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="성북마을아카이브" src={logo8}/>}>
              성북마을아카이브
              </Card>
            </a>
          </Col>
          <Col>
          <a href='https://cafe.naver.com/sbflagart' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="깃발전카페" src={logo9}/>}>
              깃발전카페
              </Card>
            </a>
          </Col>
          <Col>
          <a href='https://www.nts.go.kr/nts/cm/cntnts/cntntsView.do?mi=13386&cntntsId=109155' target='_blank' rel="noreferrer noopener">
              <Card
                cover={<img alt="국세청" src={logo10}/>}>
              국세청
              </Card>
            </a>
          </Col>
            {/*그리드 맞춤*/}
            <Col>
            </Col>
            <Col>
            </Col>
        </Row>
      </div>
  </div>
);
}

export default Familysite;
