import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/HeaderNaviMenu.css";

const getSubMenuList = (menu) => menu && menu.sublist;

const MultiStepMenu = (props)=>{
    const { menuList, openMenu, closeMenu, openList  } = props;

    const drawMultiStepMenu = (firstStepMenuList) => {
        if(Array.isArray(firstStepMenuList)){
            return firstStepMenuList.map((firstStepMenu,index)=>{
                let secondStepMenuList = getSubMenuList(firstStepMenu);
                return (
                    <li className="navMenu" 
                        key={`multi-step-first-menu-${index}`}
                        value={firstStepMenu.value} 
                        onClick={closeMenu}
                        >
                        <Link to={{
                            pathname:`/${firstStepMenu.groupValue}/${firstStepMenu.value}`,
                            state:{ 
                                    title: `${firstStepMenu.title}`,
                                    menucategory : `${firstStepMenu.menucategory}`,
                                    value: `${firstStepMenu.value}`
                                }
                            }} 
                            onClick={openMenu}
                            onMouseEnter={openMenu}
                            >{firstStepMenu.title}</Link>
                        {drawSecondStepMenu(secondStepMenuList)}
                    </li>
                )
            })
        }
    }
    const drawSecondStepMenu = (secondStepMenuList) => {
        if(Array.isArray(secondStepMenuList)){
            return (
                <ul className="submenuUl" 
                    style={openList} 
                    onMouseLeave={closeMenu} 
                    onMouseEnter={openMenu}>
                    {
                        secondStepMenuList.map((secondStepMenu, index) => {
                            let thirdStepMenuList = getSubMenuList(secondStepMenu);
                            return(
                                <div className="submenuDiv" key={index}>
                                <li key={`multi-step-second-menu-${index}`} value={secondStepMenu.value}>
                                    <Link to={{
                                        pathname:`/${secondStepMenu.groupValue}/${secondStepMenu.value}`,
                                        state:{ title: `${secondStepMenu.title}`,
                                                menucategory : `${secondStepMenu.menucategory}`,
                                                 value: `${secondStepMenu.value}`
                                                 }
                                        }} onClick={closeMenu}>{secondStepMenu.title}</Link>
                                </li> 
                                    {drawThirdStepMenu(thirdStepMenuList)}
                                </div> 
                            )
                        })
                    }
                </ul>
            )
        }
    }

    const drawThirdStepMenu = (thirdStepMenuList) => {
        if(Array.isArray(thirdStepMenuList)){
            return (
                        thirdStepMenuList.map((thirdStepMenu, index) => {
                            return(
                                <li className="subdep" key={`multi-step-third-menu-${index}`} value={thirdStepMenu.value}>
                                    <Link to={{
                                        pathname:`/${thirdStepMenu.groupValue}/${thirdStepMenu.value}`,
                                        state:{ 
                                            title: `${thirdStepMenu.title}`,
                                            menucategory : `${thirdStepMenu.menucategory}`,
                                            value: `${thirdStepMenu.value}`
                                         }
                                        }}
                                        onClick={closeMenu}>{thirdStepMenu.title}</Link>
                                </li>
                            )
                        })
            )
        }
    }

    return drawMultiStepMenu(menuList);
}

export default MultiStepMenu;