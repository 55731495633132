import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import '../../assets/styles/galleryboard.css';
import { defaultApiAddress } from '../../services/api.helpers';
import { Pagination } from 'antd';
import BasicList from './BasicList';
import BigList from './BigList';
import TextList from './TextList';

const GalleryList = (props) => {
  const { type, cate, group, init, userObj } = props;
  const history = useHistory();
  const pathName =  `/${group}/${cate}`;
  const [ data, setData ] = useState(null);
  const [ page, setPage ] = useState(0);
  const [ totalCnt, setTotalCnt ] = useState(0);

  const [ cateFilter, setCateFilter ] = useState(null);
  const [ cateData, setCateData ] = useState(null);

  const onCateChange = (value) => {
    setCateFilter(value);
  }

  async function fetchData() {
    setData(null);
    const result = await axios.get(`${defaultApiAddress}/boards?type=${type}&cate=${cate}&rpp=12&page=${page}`);

    setData(result.data.results);
    setCateData(result.data.results);
    setTotalCnt(result?.data?.totalCount);
    console.log(result.data);
  }

  async function fetchDataCate() {
    setCateData(null);
    const result = await axios.get(`${defaultApiAddress}/boards?type=${type}&cate=${cate}&rpp=12&page=${page}&d1=${cateFilter?.depth1}&d2=${cateFilter?.depth2}`);
    setCateData(result.data.results);
    setTotalCnt(result?.data?.totalCount);
    console.log(result.data);
  }

  // 최초 렌더링 시 발생할 effect
  useEffect(() => {
    if (init == 'notLogined' || (!!init && !!userObj)) {
      if (cate == 'cultural' || cate == 'hfigure') {
        if (cateFilter !== null) {
          if ((cateFilter.depth1 == '' && cateFilter.depth2 == '')) {
            fetchData();
          } else {
            fetchDataCate();
          }
        }
      } else {
        fetchData();
      }
    }
    return () => {setData(null)}
  }, [page, cateFilter]);

  const onChange = (current) => {
    let crr = (current-1);
    console.log(crr);
    return (
      setPage(crr)
    );
  }

  const matchViewComponent =(item) => {
    switch (item) {
      case 'e-culrture' : return <BasicList data={data} pathName={pathName} />; break;
      case 'e-academic' : return <BasicList data={data} pathName={pathName} />; break;
      case 'gallery' : return <BigList data={data} pathName={pathName} />; break;
      case 'cultural' : return <TextList data={data} pathName={pathName} cate={cate} onCateChange={onCateChange} cateData={cateData} />; break;
      case 'hfigure' : return <TextList data={data} pathName={pathName} cate={cate} onCateChange={onCateChange} cateData={cateData} />; break;
    }
  }

  return (
    <>
      { matchViewComponent(cate) }
       <Pagination
          defaultCurrent={page}
          current={page+1}
          pageSize={12}
          total={totalCnt}
          onChange={onChange}
          size='small'
        />
    </>
    );
  }


  export default GalleryList;
