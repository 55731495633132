import React from 'react';

function PersonalTerm(){
    return(
        <>
            <div className="ntemp">
                <h2 className="title">&#60; 성북문화원 &#62; 개인정보 취급방침</h2>
                최종 수정일 : 2021년 3월 25일
                <br/>
                    <h1 className="ntitle"> 제 1조 (총칙)</h1>
                    <ul className="nlist">
                        <li>1. 개인정보란 생존하는 개인에 관한 정보로서 성명, 휴대폰번호 등에 의하여 당해 개인을 알아볼 수 있는 부호, 문자, 음성, 음향, 영상 및 생체 특성 등에 관한
                            정보(당해 정보만으로는 특정 개인을
                            알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함)를 말합니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>2. ' 성북문화원 홈페이지 ' 서비스는 고객님의 개인정보를 중요시하며, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』과 개인정보 보호 관련 각종 법규를
                            준수하고 있습니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>3. ' 성북문화원 홈페이지 ' 서비스의 개인정보취급방침을 통하여 고객님의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
                            취해지고 있는지 알려드립니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>4. ' 성북문화원 홈페이지 ' 서비스의 개인정보취급방침은 관련 법령 및 내부 운영 방침의 변경에 따라 개정될 수 있습니다. 개인정보취급방침이 개정되는 경우에는
                            시행일자 등을 부여하여 개정된 내용을
                            홈페이지(http://www.isbcc.or.kr)에 지체 없이 공지합니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>5. 영업의 전부 또는 일부를 양도하거나 합병 등으로 개인정보를 이전하는 경우 Push 알림, 메일 등을 통하여 고객님께 개별적으로 통지하고, ' 성북문화원 홈페이지
                            ' 서비스는 과실 없이 고객님의 연락처를 알 수 없어
                            메일로 통지할 수 없는 경우에는 홈페이지(http://www.isbcc.or.kr), 첫 화면에서 식별할 수 있도록 표기하여 30일 이상 그 사실을 공지합니다.
                            단, 천재지변 등 정당한 사유로 홈페이지 게시가 곤란한 경우에는 2곳 이상의 중앙일간지(고객님의 대부분이 특정 지역에 거주하는 경우에는 그 지역을 보급구역으로 하는
                            일간지로 할 수 있습니다.)에 1회 이상
                            공고하는 것으로 갈음합니다.
                        </li>
                    </ul>
                    <h1 className="ntitle">제 2조 (개인정보의 수집 및 이용 목적)</h1>
                    <ul className="nlist">
                        <li>1. ' 성북문화원 홈페이지 '는 회원가입, 상담, 서비스 제공 등을 위하여 필요한 범위에서 최소한의 개인정보만을 수집합니다.</li>
                    </ul>
                    <ul className="nlist">
                        <li>2. 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 고객님의 권리, 이익이나 사생활을 뚜렷하게 침해할 우려가 있는 개인정보는 수집하지
                            않습니다. 다만, 고객님께서
                            수집에 동의하시거나 다른 법률에 따라 특별히 수집 대상 개인정보로 허용된 경우에는 필요한 범위에서 최소한으로 위 개인정보를 수집할 수 있습니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>3. ' 성북문화원 홈페이지 ' 서비스가 수집하는 개인정보 항목과 수집/이용하는 목적은 다음과 같습니다.</li>


                        <div className="noverflow">
                            <table className="ndatable" summary="수집한 개인정보 항목, 이용목적에 대한 정보입니다.">
                                <caption className="blind">수집한 개인정보의 항목, 이용목적</caption>
                                <colgroup>
                                    <col style={{width:"33%"}}/>
                                        <col style={{width:"33%"}}/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope="col">수집/이용 목적</th>
                                    <th scope="col">개인정보 항목</th>
                                </tr>
                                </thead>
                                <tbody>

                                <tr>
                                    <td>계정생성</td>
                                    <td>SNS 계정 정보</td>
                                </tr>
                                <tr>
                                    <td>강좌신청, 상담 등의 목적</td>
                                    <td>성명, 전화번호</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </ul>

                    <ul className="nlist">
                        <li>※ 위 정보는 가입 당시 정보뿐 아니라 정보 수정으로 변경된 정보를 포함합니다.</li>
                        <br/>
                            <li>※ 수집, 이용에 동의하여 이용자의 단말에 앱의 접근 권한을 허용하였다고 하더라도, 위에 언급된 모든 정보가 즉시 수집(전송)되지는 않으며, 이용자가 수집,
                                이용 동의한 범위내에서 고지, 승인 후
                                수집(전송) 됩니다.
                            </li>
                            <br/>
                                <li>※ 수집, 이용 동의한 개인정보는 이용자가 스마트폰 상에서 앱을 삭제하더라도 위 목적에 의하여 필요 보유기간 동안 남아 있을 수 있으며, 이에 대한
                                    삭제를 원하시는 경우
                                    고객센터(02-765-1611)로
                                    연락하여 진행 바랍니다.
                                </li>
                                <br/>
                    </ul>
                    <ul className="nlist">
                        <li> ' 성북문화원 홈페이지 '는 아래의 경우에 한하여 고객님의 동의 없이 고객님의 개인정보를 수집ㆍ이용할 수 있습니다.
                            <br/>
                                <ul>
                                    <li>가. 고객님과의 계약을 이행하기 위하여 필요한 개인정보로서 경제적ㆍ기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우</li>
                                    <li>나. 서비스의 제공에 따른 요금정산을 위하여 필요한 경우</li>
                                    <li>다. 관련 법령에 특별한 규정이 있는 경우</li>
                                </ul>
                        </li>
                    </ul>

                    <h1 className="ntitle">제 3조 (개인정보의 수집방법)</h1>
                    <p className="ndesc">' 성북문화원 홈페이지 '는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
                    <ul className="nlist">
                        <li>가. ' 성북문화원 홈페이지 ' 문화강좌 신청, 문의하기 등의 과정에서 수집</li>
                    </ul>
                    <ul className="nlist">
                        <li>나. 홈페이지에 기재된 자료파일 다운로드 시 고객이 제공한 정보 수집</li>
                    </ul>


                    <h1 className="ntitle">제 4조 (개인정보의 보유 및 이용기간)</h1>
                    <p className="ndesc"></p>
                    <ul className="nlist">
                        <li>1. ' 성북문화원 홈페이지 '는 고객님의 개인정보를 고객님께 서비스를 제공하는 기간 또는 분쟁처리 기간 동안에 한하여 보유하고 이를 이용합니다
                            <ul>
                                <li>가. 서비스 기간 (가입일 ~ 서비스 종료기간)</li>
                                <li>나. 문화강좌 신청 및 환불관련 분쟁 대비를 위해 강좌 종료 후 1년 (단, 관계 법령에 특별한 규정이 있을 경우 관련 법령에 따라 보관)</li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>2. 상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는
                            경우 기관은 관계법령에서 정한 기간
                            동안 고객의 개인정보를 보유하며, 그 기간은 다음과 같습니다.
                        </li>
                        <div className="noverflow2">
                            <table className="ndatable">
                                <colgroup>
                                    <col style={{width:"20%"}}/>
                                        <col style={{width:"65%"}}/>
                                            <col style={{width:"15%"}}/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope="col">보존 근거</th>
                                    <th scope="col">보존 대상</th>
                                    <th scope="col">보존 기간</th>
                                </tr>
                                </thead>
                                <tbody>

                                <tr>
                                    <td>상법</td>
                                    <td>상업장부와 영업에 관한 중요서류에 포함된 개인정보</td>
                                    <td>10년</td>
                                </tr>
                                <tr>
                                    <td>상법</td>
                                    <td>전표 또는 이와 유사한 서류에 포함된 개인정보</td>
                                    <td>5년</td>
                                </tr>

                                <tr>
                                    <td>국세기본법</td>
                                    <td>성명, 주민번호, 전화번호, 청구지 주소, 요금납부내역 등 거래에 관한 장부 및 증거서류</td>
                                    <td>5년</td>
                                </tr>
                                <tr>
                                    <td>통신비밀보호법</td>
                                    <td>통신사실확인자료 제공에 필요한 성명, 주민번호, 전화번호 및 가입자의 전기통신일시, 전기통신개시ㆍ종료시간, 발ㆍ착신 통신번호 등 상대방의
                                        가입자번호, 사용도수, 발신기지국의 위치정보
                                    </td>
                                    <td>12개월
                                        단, 시외/시내전화 역무와 관련된 자료 : 6개월
                                    </td>
                                </tr>
                                <tr>
                                    <td>통신비밀보호법</td>
                                    <td>컴퓨터통신 또는 인터넷의 로그기록자료, 정보통신기기의 위치를 확인할 수 있는 접속지의 추적자료</td>
                                    <td>3개월
                                    </td>
                                </tr>

                                <tr>
                                    <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                    <td>계약 또는 청약철회 등에 관한 기록 / 대금결제 및 재화 등의 공급에 관한 기록/ 소비자의 불만 또는 분쟁처리에 관한 기록 / 표시,광고에 관한
                                        기록
                                    </td>
                                    <td>5년 / 5년 / 3년 / 6개월
                                    </td>
                                </tr>
                                <tr>
                                    <td>신용정보의 이용 및 보호에 관한 법률</td>
                                    <td>신용정보의 수집/처리 및 이용 등에 관한 기록</td>
                                    <td>3년
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </ul>

                    <h1 className="ntitle">제 5조 (개인정보의 파기절차 및 방법)</h1>
                    <p className="ndesc">' 성북문화원 홈페이지 '는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은
                        다음과 같습니다.</p>
                    <ul className="nlist">
                        <li>1. 파기절차
                            <ul>
                                <li>가. 고객님의 개인정보는 수집 및 이용목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라 일정 기간
                                    저장된 후 파기됩니다.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>2. 파기방법
                            <ul>
                                <li>가. DB 등 전자적 파일 형태로 저장된 개인정보 : 재생할 수 없는 기술적 방법으로 삭제</li>
                            </ul>
                        </li>
                    </ul>

                    <h1 className="ntitle">제 6조 (수집한 개인정보의 공유 및 제공)</h1>
                    <p className="ndesc">' 성북문화원 홈페이지 '는 고객님의 개인정보를 제3자에게 제공하지 않습니다.</p>

                    <h1 className="ntitle">제 7조 (개인정보 취급의 위탁)</h1>
                    <p className="ndesc">' 성북문화원 홈페이지 '는 서비스 제공에 관한 계약을 이행하고 고객님의 편의 증진 등을 위하여 개인정보 취급업무를 외부 전문업체에게 위탁하고
                        있으며, 수탁자에 대해서는
                        “위.수탁계약서” 등을 통하여 관련 법규 및 지침의 준수, 정보보호 및 비밀유지, 제3자 제공 금지, 사고 시 책임부담, 위탁기간 종료 즉시 개인정보의 반납/파기 의무 등을
                        규정하여 관리하고
                        있습니다.</p>
                    <ul className="nlist">
                        <li>ㅇ 개인정보 취급업무 수탁자 및 위탁내용</li>

                        <div className="noverflow">
                            <table className="ndatable" summary="개인정보 취급 수탁사와 위탁목적에 대한 정보입니다.">
                                <caption className="blind">개인정보 취급의 위탁</caption>
                                <colgroup>
                                    <col style={{width:"30%"}}/>
                                        <col style={{width:"70%"}}/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope="col">수탁사</th>
                                    <th scope="col">위탁 업무 내용</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="sta">성북문화원</td>
                                    <td>- 참여자 본인 확인 및 응대, 및 행사 운영</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </ul>

                    <h1 className="ntitle">제 8조 (이용자 및 법정대리인의 권리와 그 행사 방법)</h1>

                    <ul className="nlist">
                        <li>1. 고객님(만 14세 미만 아동의 경우 법정대리인 포함) 께서는 언제든지 개인정보, 개인정보를 이용하거나 제3자에게 제공한 현황, 개인정보 수집ㆍ이용ㆍ제공 등의
                            동의를 한 현황(이하 ‘개인정보
                            등’이라 합니다)에 대한 열람이나 제공을 요구하실 수 있고, 오류가 있는 경우에는 그 정정을 요구하실 수 있으며, 개인정보의 수집ㆍ이용ㆍ제공에 대한 동의를 철회를
                            하실 수 있습니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>2. 고객님의 개인정보 등에 대한 열람 및 정정을 위해서는 고객님(법정대리인)께서 회사를 직접 방문하시거나 고객센터로 전화 문의 하실 수 있습니다.
                            가입해지(동의철회)를 위해서는 ' 성북문화원 홈페이지 ' 앱의
                            ‘이용철회’를 클릭하여 탈퇴가 가능합니다.
                            <ul>
                                <li>ㅇ 탈퇴관련 문의: 성북문화원 담당자</li>
                            </ul>
                            <ul>
                                <li>ㅇ 전화번호: 02-765-1611</li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>3. 고객님께서 본인의 개인정보 등에 대한 열람이나 정정을 요구하시거나 개인정보 수집ㆍ이용ㆍ제공 등의 동의를 철회하시는 경우 고객님의 신분을 증명할 수 있는
                            주민등록증, 여권, 운전면허증(신형)
                            등의 신분증명(사본)을 제시 받아 본인 여부를 확인합니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>4. 고객님의 대리인이 고객님의 개인정보 등에 대한 열람이나 정정을 요구하거나 고객님의 개인정보의 수집ㆍ이용ㆍ제공에 대한 동의를 철회하는 경우에는 대리 관계를
                            나타내는 위임장, 명의고객님의
                            인감증명서와 대리인의 신분증명서 등의 증표를 제시 받아 적법한 대리인인지 여부를 확인합니다.
                        </li>
                    </ul>

                    <ul className="nlist">
                        <li>5. 고객님께서는 개인정보 등의 열람이나 제공을 요청하실 수 있으며, ' 성북문화원 홈페이지 '은 이러한 요청에 지체 없이 필요한 조치를 취합니다.</li>
                    </ul>

                    <ul className="nlist">
                        <li>6. 고객님께서 개인정보 등의 오류에 대한 정정을 요청하신 경우에는 지체 없이 그 오류를 정정하거나 정정하지 못하는 사유를 이용자에게 알리는 등 필요한 조치를 하고,
                            필요한 조치를 할 때 까지는
                            당해 개인정보를 이용 또는 제공하지 않습니다.
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>7. ' 성북문화원 홈페이지 '은 고객님의 요청에 의해 해지 또는 삭제된 개인정보를 “개인정보의 보유 및 이용기간” 에 명시된 바에 따라 처리하고 그 외의 용도로
                            열람 또는 이용할 수 없도록 처리하고
                            있습니다.
                        </li>
                    </ul>


                    <h1 className="ntitle">제 9조 (개인정보 자동 수집장치의 설치 운영 및 그 거부에 관한 사항)</h1>
                    <p className="ndesc">' 성북문화원 홈페이지 '는 홈페이지 운영에 있어 필요 시 고객님의 정보를 찾아내고 저장하는 '쿠키(Cookie)'를 운용합니다. 쿠키는 '
                        성북문화원 홈페이지 '의 웹사이트를 운영하는데 이용되는
                        서버가 고객님의 브라우저에 보내는 아주 작은 텍스트 파일로서 고객님의 컴퓨터 하드디스크에 저장됩니다. 고객님께서는 웹 브라우저의 보안 정책을 통해 쿠키에 의한 정보수집의
                        허용 거부 여부를 결정하실 수
                        있습니다.
                    </p>
                    <ul className="nlist">
                        <li>1. 쿠키에 의해 수집되는 정보 및 이용 목적
                            <ul>
                                <li>가. 수집 정보 : 접속IP, 접속로그, 이용 컨텐츠 등 서비스 이용정보
                                </li>
                            </ul>
                            <ul>
                                <li>나. 이용목적 : 회원과 비회원의 접속 빈도나 방문 시간 등을 원활한 서비스를 위해 활용
                                </li>
                            </ul>

                        </li>
                        <li>2. 고객님은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹 브라우저에서 옵션을 설정함으로써 쿠키에 의한 정보 수집 수준의 선택을 조정하실 수 있습니다.
                            <ul>
                                <li>가. 웹 브라우저의 [도구]메뉴 [인터넷옵션]->[개인정보]에서 쿠키에 의한 정보 수집 수준을 정할 수 있습니다.</li>
                            </ul>
                            <ul>
                                <li>나. 위에 제시된 메뉴를 통해 쿠키가 저장될 때마다 확인을 하거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 고객님께서 쿠키 설치를
                                    거부하였을 경우 서비스 제공에 어려움이
                                    있을 수 있습니다.
                                </li>
                            </ul>

                        </li>

                    </ul>

                    <h1 className="ntitle">제 10조 (개인정보의 기술적, 관리적 보호)</h1>
                    <p className="ndesc">' 성북문화원 홈페이지 '는 고객님의 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
                        기술적·관리적 대책을 마련하고 있습니다.</p>
                    <ul className="nlist">
                        <li>[기술적 보호대책]
                            <ul>
                                <li>1. 고객님의 개인정보를 암호화하여 저장하며, 정보통신망을 통해 고객님의 개인정보 및 인증정보를 송,수신 하는 경우에는 안전한 보안서버 구축 등의
                                    조치를 통해 암호화 하여 보호하고
                                    있습니다.
                                </li>
                            </ul>
                            <ul>
                                <li>2. 백신 소프트웨어를 이용하여 컴퓨터바이러스 등에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신 소프트웨어는 주기적으로 업데이트되며
                                    갑작스런 바이러스가 출현할 경우 백신이
                                    나오는 즉시 이를 도입, 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                                </li>
                            </ul>
                            <ul>
                                <li>3. 고객님의 개인정보에 대한 불법적인 접근을 차단하기 위해, 외부로부터 접근이 통제된 구역에 시스템을 설치하고, 침입을 차단하는 장치를 이용하고
                                    있으며, 아울러 침입탐지시스템을 설치하여
                                    24시간 침입을 감시하고 있습니다.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>[관리적 보호대책]
                            <ul>
                                <li>1. ' 성북문화원 홈페이지 '는 고객님의 개인정보를 안전하게 취급하기 위한 내부관리계획을 마련하여 소속 직원이 이를 숙지하고 준수하도록 하고
                                    있습니다.
                                </li>
                            </ul>
                            <ul>
                                <li>2. ' 성북문화원 홈페이지 '는 고객님의 개인정보를 취급할 수 있는 자를 최소한으로 제한하고 접근 권한을 관리하며, 새로운 보안 기술 습득 및
                                    개인정보보호 의무 등에 관해 정기적인 사내 교육과
                                    외부 위탁교육을 통하여 법규 및 정책을 준수할 수 있도록 하고 있습니다. 고객님의 개인정보를 취급하는 자는 다음과 같습니다.
                                    <ul>
                                        <li>가. 고객님을 직·간접적으로 상대하여 마케팅 업무를 수행하는 자</li>
                                    </ul>
                                    <ul>
                                        <li>나. 개인정보관리책임자 및 개인정보관리담당자 등 개인정보 관리 및 개인정보보호 업무를 담당하는 자</li>
                                    </ul>
                                    <ul>
                                        <li>다. 기타 업무상 개인정보의 취급이 불가피한 자</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>3. 전 임직원이 정보보호서약서에 서명하게 함으로써 직원에 의한 정보(개인정보 포함)유출을 사전에 방지하고, 수시로 개인정보보호 의무를 상기시키며 준수
                                    여부를 감사하기 위한 내부 절차를
                                    마련하여 시행하고 있습니다.
                                </li>
                            </ul>
                            <ul>
                                <li>4. 개인정보 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지도록 하여, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확하게
                                    규정하고 있습니다.
                                </li>
                            </ul>
                            <ul>
                                <li>5. 전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.</li>
                            </ul>
                            <ul>
                                <li>6. ' 성북문화원 홈페이지 '는 고객님 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 고객님의 개인정보를
                                    보호하기 위해서 자신의 ID와 비밀번호를
                                    철저히 관리하고 책임을 져야 합니다.
                                </li>
                            </ul>

                        </li>
                    </ul>
                    <h1 className="ntitle">제 11조 (개인정보 관리책임 부서 및 연락처)</h1>
                    <p className="ndesc">' 성북문화원 홈페이지 '는 고객님의 개인정보를 보호하고 개인정보와 관련한 불만 및 문의사항을 처리하기 위하여 아래와 같이 관련 부서를
                        지정하여 운영하고 있습니다. 또한 고객님의 의견을
                        매우 소중하게 생각합니다. 고객님께서 ' 성북문화원 홈페이지 ' 서비스의 개인정보 관련 문의사항이 있을 경우 아래 개인정보 관리책임자 및 담당자에게 문의하시면 신속하고
                        성실하게 답변을 드리겠습니다.</p>
                    <ul className="nlist">
                        <li>1. 개인정보 관리책임 부서 및 연락처
                            <ul>
                                <li>가. ' 성북문화원 홈페이지 ' 개인정보 보호책임자 박수진 부장</li>
                                <ul>
                                    <li>ㅇ 전화번호: 02-765-1611</li>
                                </ul>
                                <ul>
                                    <li>ㅇ 이메일: isbcc1@naver.com</li>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>2. 개인정보 관리책임자 역할
                            <ul>
                                <li>가. 개인정보보호 관련 주요 지침/정책/제도 수립·시행</li>
                                <li>나. 개인정보 유출, 오남용 방지를 위한 내부통제시스템 구축</li>
                                <li>다. 개인정보보호 분야 관리실태 감사 및 지도점검</li>
                                <li>라. 개인정보보호 교육 계획 수립 시행</li>
                                <li>마. 개인정보취급방침 수립 개정</li>
                                <li>바. 개인정보 침해사고 예방 대응</li>
                                <li>사. 기타 기타정보보호를 위하여 필요한 업무</li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="nlist">
                        <li>3. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                            <ul>
                                <li>가. 개인정보침해신고센터 : http://privacy.kisa.or.kr / (국번없이)118</li>
                            </ul>
                            <ul>
                                <li>나. 대검찰청 사이버수사과 : http://www.spo.go.kr / 02-3480-3571</li>
                            </ul>
                            <ul>
                                <li>다. 경찰청 사이버안전국 : http://www.netan.go.kr / 1566-0112</li>
                            </ul>
                        </li>
                    </ul>

                    <h1 className="ntitle"> 제12조 개인정보취급방침의 고지</h1>
                    <p className="ndesc">개인정보취급방침 공고일자 : 2021/03/25<br/>개인정보취급방침 시행일자 : 2021/03/25<br/></p>


            </div>
        </>
    )
}

export default PersonalTerm;
