import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Cascader,
  Modal,
  Result,
  Typography
} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {defaultApiAddress, refreshToken} from '../../services/api.helpers'
import {authService} from "../../middleware/fb";

const { Text } = Typography;
const { TextArea } = Input;

const Inquiry = (props) => {

  const { userObj, token } = props;
  const history = useHistory();
  const [inquiryForm] = Form.useForm();
  const [ errorModal, setErrorModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ type, setType ] = useState({
    type: null
  });
    const [localToken, setLocalToken] = useState(null);

  useEffect(() => {
        setType({type: history?.location?.state?.type});
        refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
  }, [history.location])

  console.log(history?.location?.state)
  console.log(history?.location?.state?.title)

  const  formItemLayout= {
    labelCol: { span: 4 },
  wrapperCol: { span: 18 },
  }

  const btnSubmitSt = {
    width:'175px',
    backgroundColor:'#FFD560',
    letterSpacing:'0.25em',
    color:'#000',
    border:0,
    marginTop:25,
  }

    function loginChk() {
        if (!authService.currentUser) {
            console.log('not logined');
            setErrorModal(true);
        } else {
            console.log('logined success');
        }
    }

    useEffect(()=>{
        setTimeout(loginChk, 3000);
    },[userObj]);

    const ontypeChange = (value) => {
    let setString = value.toString();
    console.log(setString)
    setType({
      type: setString
    });
  }

const handleSuccess = () => {
    setIsModalVisible(false);
    history.push('/mypage/inquiry')
}

const handleFail = () => {
    setErrorModal(false);
    history.push('/signin')
}

const handleSubmit = (value) => {
  console.log(value);
  value.type = type.type;
  console.log(value);

  if(userObj != null){
      axios.post(`${defaultApiAddress}/inquiries/`, value, {headers: {
              "Authorization" : localToken,
              "Content-Type" : "application/json",
              "Access-Control-Allow-Origin" : "*",
          }})
      .then(function (res){
          console.log('성공!'+ res.data.results);
          setIsModalVisible(true);
        })
      .catch(function (error) {
          if(error.response){
              console.log(error.response.data);
            }
        });
  }else{
    setErrorModal(true);
    }
}

return (
    <>
        <div id="Inquiry" className="static container">
            <Row justify="center">
                <Text mark> * 회원만 문의가 가능합니다.</Text>
            </Row>
            <Row className="textWrapper" style={{margin:'3% 0'}}>
                <h2 className="ant-col-4 title"><strong>1:1 문의</strong></h2>
                <p className="ant-col-16">
                    접수된 문의는 순차적으로 처리됩니다. 문의가 급격히 증가하는 경우, <br className='onlyPC'/>
                    답변처리가 다소 지연될 수 있음을 양해부탁드립니다.
                    빠르고 정확한 답변을 위해 최선을 다하겠습니다.</p>
            </Row>
            <Form
                form={inquiryForm}
                initialValues={ history.location?.state?.course ? history.location?.state : null }
                layout="horizontal"
                {...formItemLayout}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="문의 유형"
                    className='seletinput'
                    style={{position: 'relative', overflow: 'hidden', height:'40px'}}
                    rules={[
                        {
                            required: true,
                            message: '제목을 입력하세요',
                        }
                    ]}
                >
                    <Cascader
                        defaultValue={[history.location?.state?.type]}
                        style={{ width:"168px" }}
                        onChange={ontypeChange}
                        options={[
                            {
                                value: '강좌문의',
                                label: '강좌문의',
                                children: [
                                    {
                                        value: '수강문의',
                                        label: '수강문의',
                                    },
                                    {
                                        value: '취소문의',
                                        label: '취소문의',
                                    },
                                    {
                                        value: '환불문의',
                                        label: '환불문의',
                                    }
                                ],
                            },{
                                value: '행사문의',
                                label: '행사문의'
                            },{
                                value: '역사문의',
                                label: '역사문의'
                            },{
                                value: '기타문의',
                                label: '기타문의'
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name='title'
                    label="문의 제목"
                    rules={[
                        {
                            required: true,
                            message: '제목을 입력하세요',
                        }
                    ]}
                >
                    <Input
                        placeholder="제목을 입력하세요"
                        // defaultValue={`${history.location?.state.title} 강좌 문의)`}
                        // value={data.title}
                    />
                </Form.Item>
                <Form.Item
                    name='description'
                    label="문의 내용"
                    rules={[
                        {
                            required: true,
                            message: '내용을 입력하세요',
                        }
                    ]}
                    style={{marginBottom: '3%'}}
                >
                    <TextArea placeholder="내용을 입력하세요" style={{height: '243px'}} />
                </Form.Item>
                <Form.Item
                    label='ㅤ'
                    style={{textAlign: 'center'}}
                >
                    <Button type="primary" htmlType="submit" className="btnL" style={btnSubmitSt}>
                        등록
                    </Button>
                </Form.Item>
            </Form>
        </div>
        <Modal
            visible={isModalVisible}
            className="applyModal"
            width="100%"
            footer={null}>
            <Result
                status="success"
                title="문의가 접수되었습니다."
                subTitle=""
                extra={[
                    <Button className="btnApply" type="primary" key="console" onClick={handleSuccess}>
                        확인
                    </Button>,
                ]}
            />
        </Modal>
        <Modal
            visible={errorModal}
            className="applyModal"
            style={{top:'25vh'}}
            width="100%"
            footer={null}>
            <Result
                status="error"
                // status="warning"
                title="로그인 후 문의 가능합니다."
                subTitle="로그인 후 다시 시도해 주세요."
                extra={[
                    <Button className="btnApply" type="primary" key="확인" onClick={handleFail}>
                        확인
                    </Button>,
                ]}
            />
        </Modal>
    </>
  );
};

export default Inquiry;
