import React from "react";
import season from "../../../assets/img/apply-season.jpg";
import step from "../../../assets/img/apply-step.jpg";

function CourseGuide() {
    return(
        <div id='CourseGuide' className="static container">
            {/* <p>문화강좌 접수방법 및 환불규정 등 </p> */}
             <h2 className='Subtitle'>수강안내</h2>

             <div className='SubWrapper'>
                <h3> 수강기간 (총 12주)</h3>
                <img src={season} alt="수강기간 안내" style={{margin:'0 auto'}}/>
            </div>

            <div className='SubWrapper'>
                <h3> 신청방법</h3>
                <p style={{margin:'.5em', fontWeight:'bold'}}>① 인터넷 접수</p>
                <img src={step} alt="인터넷 접수 절차"  style={{margin:'0 auto'}}/>
            </div>

            <ul className='SubWrapper'>
                <li>인터넷 접수 첫날은 오전 9시부터 접수가 가능합니다.</li>
                <li>인터넷 접수 후 수강료를 아래 계좌로 1일 이내에 납부하셔야 수강신청이 완료됩니다.<br/>
                    <b>* 현금영수증은 전화로 신청해주시거나 개강 후 2층 사무국에서 발행 가능합니다.</b><br/>
                    <b style={{color:'#EB5757'}}>* 문화강좌 수강 신청 이전 입금된 것은 무효처리됩니다. 꼭 강좌 신청 이후 입금해 주세요.</b><br/>
                </li>

                <li>수강료 입금 시, 수강생 본인 이름을 기재해 주시기 바랍니다.</li>
                <li>타인명의 입금 (배우자 및 자녀 명의) 등 신청자의 부주의로 인한 피해는 기관에서 책임지지 않습니다.</li>
            </ul>

             <div className='Account'>우리은행 : <br className='onlyMobile'/>1005-201-270900 <br className='onlyMobile'/>(예금주) 성북문화원</div>

             <div className='SubWrapper'>
                <p style={{margin:'.5em', fontWeight:'bold'}}>② 방문 접수</p>
                <ul className='SubWrapper'>
                    <li>접수 첫날은 오전 9시부터 수강신청이 가능합니다.</li>
                    <li>현금/계좌이체/카드결제가 가능합니다 (현금영수증 가능).</li>
                </ul>
             </div>

             <h2 className='Subtitle'>취소 · 환불 · 변경규정</h2>
             <ul className='SubWrapper'>
                <li>취소신청 : 인터넷 / 전화 / 방문 신청</li>
                <li>환불신청 : 전화 / 방문 신청(인터넷 신청 불가)</li>
                 <i style={{visibility:"hidden"}}>예1 &#41; </i><span>* 매월 25일 전까지 신청<br/></span>
                 <i style={{visibility:"hidden"}}>예1 &#41; </i><span>* 계좌정보 및 결제카드 확인 필요<br/></span>
                <li>환불금액</li>
                <span className='dblock'>
                    <i style={{visibility:"hidden"}}>예0 &#41; </i>1) 폐강 시 : 전액환불<br/>
                    <i style={{visibility:"hidden"}}>예0 &#41; </i>2) 개강 전 : 전액환불<br/>
                    <i style={{visibility:"hidden"}}>예0 &#41; </i><i style={{visibility:"hidden"}}>2) </i>개강 후 : 당해 월을 제외한 남은 달만 환불<br/>
                    <i style={{visibility:"hidden"}}>예0 &#41; </i><i style={{visibility:"hidden"}}>2) 개강 후 :</i>[당해 기수 마지막 달(2, 5, 8, 11월) 환불 불가]<br/>
                </span>
                <li>환불금액 계산방법 : 수강료/3 x 남은 달수</li>
                <span className='dblock'><i style={{visibility:"hidden"}}>예1 &#41; </i>예 &#41; 3월 환불신청 : 수강료 90,000원/3 x 2개월(4, 5월)=60,000원<br/>
                <i style={{visibility:"hidden"}}>예0 &#41; </i><i style={{visibility:"hidden"}}>예 &#41; </i>4월 환불신청 : 수강료 90,000원/3 x 1개월(5월)=30,000원<br/>
                <i style={{visibility:"hidden"}}>예0 &#41; </i><i style={{visibility:"hidden"}}>예 &#41; </i>5월(마지막 달) 환불신청 불가<br/></span>
                 <li>환불 대신 다음 기수로 연기 불가</li>
                 <li>변경신청 : 전화 / 방문 신청(인터넷 신청 불가)</li>
                 <i style={{visibility:"hidden"}}>예0 &#41; </i><span>* 1회에 한하여 가능<br/></span>
                 <li>환불 및 변경차액 지급방법 : 무통장입금 (매월 말 일괄입금), 카드취소 (카드사별 상이) <b style={{color: '#EB5757'}}>* 현금반환 불가</b></li>
                 <li>강좌변경 : 1회에 한하여 가능</li>
                 <li>타인명의로 대리 수강한 자는 추후 수강신청 불가</li>
                 <br/>
                ※ 문화강좌 운영에 관련한 모든 사항은 성북문화원 문화학교 설치운영에 관한 규정에 준합니다.<br/>
                ※ 환불은 공정거래위원회 고시 제2020-16호 소비자 분쟁해결기준 “평생교육시설운영법”에 근거합니다.<br/>
             </ul>

             <h2 className='Subtitle'>유의사항</h2>
             <ul className='SubWrapper'>
                <p>
                <li>수강에 필요한 교재, 재료비, 악기대여비 등은 별도 개인 부담입니다.</li>
                <li>적정 수강인원에 미달될 경우 폐강될 수 있습니다.</li>
                <li>12주 교육일정 중 공휴일은 휴강이며, 별도의 보강이 없습니다. 단, 기타 사유로 인한
                휴강은 보강합니다.</li>
                <li>수강생 명의는 타인에게 대여 및 양도할 수 없으며, 위반 시 수강신청이 취소됩니다.</li>
                </p>
             </ul>
        </div>

    )
}

export default CourseGuide;
