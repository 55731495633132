import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { defaultApiAddress, defaultHeaders, refreshToken } from "../../services/api.helpers";
import { Modal, Button, Row, Col, Form, Input, Result, Typography, Checkbox, Popconfirm, message } from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {createBrowserHistory} from "history";
import {authService} from "../../middleware/fb";

const { Title, Text } = Typography;

const SignupBtn = (props) => {

    const { history, userObj, infoInputVisible, setInfoInputVisibleFn, setModeFn, setUserObjFn } = props;

    const [ applyLoading, setApplyLoading] = useState(false);
    const [ applyModal, setApplyModal] = useState(false);
    const [ errorModal, setErrorModal] = useState(false);
    const [ errMsg, setErrorMsg ] = useState(null);
    const [ successModal, setSuccessModal] = useState(false);
    const [ applyStatus, setApplyStatus] = useState(null);
    const [ applyData, setApplyData] = useState(null);
    const [ visible, setVisible] = useState(null);

    const userId = userObj.uid;

    useEffect(()=>{
        setVisible(infoInputVisible);
    },[infoInputVisible]);

    function onChange(value) {
        console.log(value)
    }
    const [applyform] = Form.useForm();

    const onApply = () => {
        setApplyModal(true);
    }

    const handleCancel = (e) => {
        e.preventDefault();

        history.push('/');
    };
/*    const cancleConfirm = () => {
        // message.success('회원가입이 완료되었습니다.');
        authService.signOut();
        history.push('/');
        createBrowserHistory({      // 로그인 / 회원가입 실패로 인한 로그아웃시 이동 페이지에서 강제 새로고침 - 네이버 로그인쪽 문제 임시방편 by Sarc
            forceRefresh: true
        });
    }
    const cancleRevert = () => {
        return false;
    }*/

    const handleOk = () => {
        setApplyLoading(true);
        setTimeout(() => {
            if(applyModal === true){
                setApplyModal(false);
            }
            setApplyLoading(false);
            setSuccessModal(true);
        }, 1000);
        setTimeout(() => {
            setSuccessModal(false);
        }, 10000);
    };

    const results = () => {
        setApplyLoading(true);
        setTimeout(() => {
            if(applyModal === true){
                setApplyModal(false);
            }
            if(applyStatus == "error"){
                setApplyLoading(false);
                setErrorModal(true);
            }else{
                setApplyLoading(false);
                setSuccessModal(true);
            }
        }, 2000);
    }

    const errorResult = () => {
        setApplyLoading(true);
        setTimeout(() => {
            if(applyModal === true){
                setApplyModal(false);
            }
            setApplyLoading(false);
            setErrorModal(true);
        }, 1000);
    }

    const onApplySubmit = (e) => {
        setApplyData(e);
        console.log(e);
        const _value = e;
        console.log(_value);
        refreshToken().then((_idToken)=>{
            axios.put(`${defaultApiAddress}/users/${userId}`, _value, {headers: {Authorization: _idToken}})
                .then(function (response){
                    // message.success('회원가입이 완료되었습니다.');
                    setUserObjFn(() => {
                        let _userObj = {...userObj};
                        if (response.data.userNameReal && response.data.phoneNumber) {
                            _userObj.userNameReal = response.data.userNameReal;
                            _userObj.phoneNumber = response.data.phoneNumber;
                        }
                        _userObj.provider = response.data.provider;
                        _userObj.uid = response.data.id;
                        _userObj.email = response.data.email;
                        _userObj.displayName = response.data.userName;
                        _userObj.photoURL = response.data.photoUrl;
                        _userObj.idToken = userObj.idToken;
                        return _userObj;
                    });

                    handleOk();
                    console.log('성공!'+response);
                    setModeFn('done');
                })
                .catch(function (error) {
                    // message.error('회원가입 실패');
                    if(error.response){
                        let err_msg = error.response.data;
                        setErrorMsg(err_msg.message);
                        errorResult();
                    }
                })
        });
    }

    const ApplyFinishFaild = (errorInfo) => {
        console.log(errorInfo);
    }

    const onSuccess = () => {
        history.push('/');
/*        history.push('/')
        createBrowserHistory({      // 로그인 / 회원가입 실패로 인한 로그아웃시 이동 페이지에서 강제 새로고침 - 네이버 로그인쪽 문제 임시방편 by Sarc
            forceRefresh: true
        });*/
    }

    return (
        <>
            {/*<Button className="btnApply" type="primary" onClick={onApply}>회원가입</Button>*/}
            <Modal
                // visible={applyModal}
                visible={visible}
                className="applyModal"
                width="100%"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <p><EditOutlined style={{fontSize:20}} /></p>
                <Title level={3}>회원정보 입력</Title>
                <Row>
                    <Form
                        labelCol={{ span: 6 }}
                        name="applyForm"
                        form={applyform}
                        layout="vertical"
                        className="applyForm"
                        onFinish={onApplySubmit}
                        onFinishFailed={ApplyFinishFaild}
                    >
                        <Row style={{maxWidth:"90%", margin: "25px auto", minWidth: "200px"}}>
                            <Form.Item
                                label="이름"
                                name="userNameReal"
                                rules={[{ required: true, message: '이름을 입력하지 않았습니다.'}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="연락처"
                                name="phoneNumber"
                                rules={[{ required: true, message: '연락처를 입력하지 않았습니다.'}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="turmChkd"
                                defaultValue={false}
                                valuePropName="checked"
                                // style={{ textAlign:"left"}}
                                rules={[{ required: true, message: '* 정보 제공에 동의해야 신청이 완료됩니다.'}]}
                            >
                                <Checkbox>
                                    <span style={{color: "#333333", fontSize:14, fontWeight:"bold"}}>정보 제공에 동의합니다.<span style={{color: "#666666", fontSize:12, fontWeight:500}}> (수집된 정보는 강의 목적 용도로만 사용됩니다.)</span></span>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item style={{margin:"50px 0 20px"}}>
                                {/*<Popconfirm
                                    title="회원정보 추가입력창을 빠져나가시겠습니까? 추후 수정 가능합니다."
                                    onConfirm={cancleConfirm}
                                    onCancel={cancleRevert}
                                    okText="예"
                                    cancelText="아니오"
                                >*/}
                                    <Button className="applyModalBtn" key="back" onClick={handleCancel}>
                                        다음에 입력
                                    </Button>
                                {/*</Popconfirm>*/}
                                <Button className="applyModalBtn" key="submit" htmlType="submit" style={{backgroundColor:"#FFD560", marginLeft:20}}
                                        type="primary" loading={applyLoading}>
                                    등 록
                                </Button>
                            </Form.Item>
                        </Row>

                    </Form>

                </Row>
            </Modal>
            <Modal
                visible={errorModal}
                className="applyModal"
                width="100%"
                footer={null}>
                <Result
                    status="error"
                    title="회원가입에 실패하였습니다."
                    subTitle={errMsg}
                    extra={[
                        <Button className="btnApply" type="primary" key="확인" onClick={handleCancel}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>
            <Modal
                visible={successModal}
                className="applyModal"
                width="100%"
                footer={null}>
                <Result
                    status="success"
                    title="회원가입에 성공하였습니다."
                    subTitle=""
                    extra={[
                        <Button className="btnApply" type="primary" key="console" onClick={onSuccess}>
                            확인
                        </Button>,
                    ]}
                />
            </Modal>

        </>
    );
}

export default SignupBtn;
