import React, { useState, useEffect } from "react";
// 지도 이미지
// import "../../../assets/img/dongMap.png"; //전체
// import jl from "../../../assets/img/dongMap-jl.png"; //정릉동
// import bm from "../../../assets/img/dongMap-bm.png"; //보문동
// import da from "../../../assets/img/dongMap-da.png"; //돈암동
// import ds from "../../../assets/img/dongMap-ds.png"; //동선동
// import gu from "../../../assets/img/dongMap-gu.png"; //길음동
// import jw from "../../../assets/img/dongMap-jw.png"; //장위동
// import sb from "../../../assets/img/dongMap-sb.png"; //성북동
// import sg from "../../../assets/img/dongMap-sg.png"; //석관동
// import ss from "../../../assets/img/dongMap-ss.png"; //삼선동
// import wg from "../../../assets/img/dongMap-wg.png"; //월곡동
// import aa from "../../../assets/img/dongMap-aa.png"; //안암동
// import ja from "../../../assets/img/dongMap-ja.png"; //장위동
import { Button } from 'antd';
import MobileImg from '../../../assets/img/dongMap-mobile.png'


const content = [
  {
    id:100,
    img: "https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FBjy1B%2Fbtq08vSdiMf%2FusKqtM9fl0QnAVCXeeRHe1%2Fimg.png",
    tab: '전체',
    name: '성북구 동명 유래',
    content: '',
    none: ''
  },
  {
    id:0,
    img: "https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FNja72%2Fbtq0Y4Cw4zo%2FD6MfP4ZPaJWck1BjmvHQFk%2Fimg.png",
    tab: '길음동',
    name: '기리묵골, 기레미골에서 유래된 길음동',
    content: '길음동은 동선동에서 미아리고개를 넘으면 첫 번째로 나오는 동네이다. 이 곳은 ‘기리묵골’ 또는 ‘기레미골’로 불렀는데, 골짜기가 길게 놓여 있는 동네라는 뜻이다. 이를 한자화하여 길음동(吉音洞)이 되었다고 전해진다. 비가 많이 오면 이 일대가 물이 많이 차고 길이 질다고 하여 ‘기리미골’ 또는 ‘지리미골’로 불리었다고도 한다. 또 계곡을 흐르는 물소리가 맑고 고와서 좋은 소리가 들리는 동네라는 뜻으로 이름이 붙여졌다고도 전해진다. 1913년에는 미아리 공동묘지가 설치되어 많은 이들이 이곳에 묻혔는데 해방 이후 사람들이 모여들고 개발이 이루어지며 사라졌고, 지금은 길음뉴타운이 자리잡고 있다.',
   },
  {
    id:1,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbvRXle%2Fbtq01qE4HJE%2FeF11Aqo1OkoLnJkLtPifFk%2Fimg.png',
    tab: '돈암동',
    name: '되너미고개에서 유래된 돈암동',
    content: '현재 돈암동에 위치한 미아리고개는 조선시대 여진인(되놈) 무리가 한양으로 들어올 때 이용했던 길이어서 ‘되너미고개’라고 불렸는데, 이를 한자로 돈암현(敦岩峴)이라고 하였다. 돈암동의 동명은 여기서 유래되었다. 오늘날의 미아리고개는 여러 차례 공사를 통하여 낮게 깎은 것으로 본래는 몹시 가파르고 험준한 고개였다고 한다. 따라서 이곳을 넘어가다가 밥을 다시 먹게 된다, 되먹게 된다 하여 ‘되너미고개’라고 불렸다는 설도 있다. 비록 험준하였으나 조선시대 주요 간선도로 역할을 한 경원가도와 가장 가까웠기에 도성으로 가는 주요한 길로 이용되었다. 일제강점기에는 1936년 경성부 최초의 토지구획정리사업 지구로 돈암동지구가 선정되어 일대가 서울의 신시가지로 주목받기도 하였다.'
  },
  {
    id:2,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FmUYop%2Fbtq04MHcmDe%2FDC6C59YrjbwQQMRY3j8h71%2Fimg.png',
    tab: '동선동',
    name: '동소문동의 동자와 삼선동의 선자를 따서 만든 동선동',
    content: "동선동의 이름은 동소문동(東小門洞)의 '동' 자와 삼선동(三仙洞)의 '선' 자를 따서 만들어졌다. 원래 이 지역은 돈암동에 속했는데, 인구가 증가하자 1949년 동선동이라는 이름으로 분리되었다. 오늘날 동선동에는 성신여자대학교가 자리한 이후 젊은이들이 운집하며 발달한 상가거리인 ‘하나로거리’가 조성되어 있다. 또한 동선동은 북동쪽으로 미아리고개, 북서쪽으로는 아리랑고개와 각각 이어져있고, 남쪽으로는 성북천과 맞닿아 있는 교통의 요충지이다."
    },
  {
    id:3,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FkGVO5%2Fbtq03vsAvYa%2F1grvVYD0sUxA7FidqsjrG0%2Fimg.png',
    tab: '보문동',
    name: '보문사에서 유래된 보문동',
    content: '성북구의 남단에 위치한 보문동의 이름은 보문동에 위치한 오랜 절인 보문사(普門寺)에서 유래했다. 보문사는 고려 1115년(예종 10) 담진이 여승들의 수련장으로 창건한 비구니 사찰로, 이 지역에 탑이 있어 탑동 혹은 탑골로도 불렸으므로   ‘탑골승방’으로 불리기도 했다. 보문동이 본격적인 주거지역이 된 것은 대체로 한국전쟁 이후이다. 주민들의 증언에 의하면 옛 보문동은 아무것도 없는 모래벌판이었기에 ‘모래마을’이라고 불렸다고 한다. 다만 보문동의 일부 지역은 1936년 도시구획정리사업에 포함되어 있어 모든 지역이 모래벌판은 아니었던 것으로 보인다.'
  },
  {
    id:4,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FM0O2w%2Fbtq03FV99bh%2F31bkiZhqLpNltWmTpk7EO0%2Fimg.png',
    tab: '삼선동',
    name: '평평하고 드넓은 땅, 삼선평에서 유래한 삼선동',
    content: '한양도성에서 혜화문이 있는 낮은 언덕을 지나면 넓은 평지가 펼쳐진다. 이곳은 예부터 삼선평이라고 불렸는데, 삼선동의 이름은 여기에서 유래했다. 삼선(三仙)이라는 이름은 삼선동 남쪽 옥녀봉 봉우리에서 하늘에서 내려온 세 명의 신선이 옥녀와 더불어 놀았기 때문이라고 한다. 옥녀 역시 선녀를 의미하니 여러 신선이 어울리던 전설이 남아 있던 곳이다. 성북동에 어영청의 북둔이 설치된 후 삼선평의 드넓은 평지는 군사 훈련장으로 사용되었다. 근대에는 한국 최초의 축구 경기와 운동회가 열렸다. 청중이 많이 운집하는 시국 강연회도 종종 열렸는데, 도산 안창호도 이 곳에서 시국강연을 하였다고 전해진다.'
  },
  {
    id:5,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbfUpf2%2Fbtq1zusGfJh%2F8lE1C59JhRJQEeJCqWcWe0%2Fimg.png',
    tab: '석관동',
    name: '‘돌곶이’ 마을에서 유래된 석관동',
    content: '석관동이라는 이름은 천장산의 동쪽 줄기가 돌을 겹겹이 꿰어놓은 듯하다고 하여 동네 이름을 돌곶이라고 불렀고 그것을 한자로 돌 석(石)자와 꿸 관(串)자를 사용하여 석관이라 한 것에서 유래되었다. 조선시대에는 장위리 혹은 석관리, 석관현 등으로 불린 이 지역은 왕이 선대 왕의 능에 행차할 때 자주 지나던 지역으로, 금군(禁軍)의 활 솜씨를 시험했다거나 열병을 했다는 기록도 남아있다. 천장산 자락에는 조선 경종과 선의왕후의 능인 의릉이 있는데, 1960년대 옛 중앙정보부가 이곳에 자리를 잡으며 여러 수난을 겪기도 하였다.'
    },
  {
    id:6,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FPAh77%2Fbtq05o0nbcy%2FL0iQG0PiKD4KK0BWlOyN4k%2Fimg.png',
    tab: '성북동',
    name: '성의 북쪽 첫 동네 - “성북동”',
    content: '조선을 건국한 이성계는 한양을 도성으로 정했으며, 숙정문과 혜화문을 연결하는 북쪽 성곽 바깥쪽을 자연스럽게 성의 북쪽, 즉 ‘성북’이라고 부르기 시작했다. 영조대에 이르러 도성의 치안을 위해 어영청의 북둔을 지금의 성북동 자리에 설치하여 본격적으로 마을이 들어서게 되었다. 돌이 많고 험한 산골이었지만 도성과 가깝다는 이점과 복숭아나무가 많고 자연풍광이 아름다워 ‘북둔도화(北屯桃花)’라 일컬어졌다는 점 등으로 양반들의 별장지대로 이용되었다. 이러한 성북동의 자연환경은 일제강점기 이후에도 많은 문화예술인들이 성북동에 자리잡고 이 곳을 중심으로 활동하는 계기가 되었다.'
  },
  {
    id:7,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F3T5Tg%2Fbtq05prrshg%2FWQFFQpg9nU4QvOKNQTguv1%2Fimg.png',
    tab: '안암동',
    name: '‘앉일바위’ 라 하던 것을 한자로 옮겨 쓴 것에서 유래된 안암동',
    content: '안암동은 문헌에 나타나는 서울의 동명 중 가장 오래된 곳이다. 안암이라는 이름은 현재 대광아파트 단지 가운데에 20여 명이 앉아 쉴 만한 큰 바위가 있어 ‘앉일바위’라 불렀고, 한자로 ‘안암(安巖)’이라고 쓴 데에서 유래되었다고 전해진다. 안암동에는 궁리(宮里), 원리(園里)라고 불리는 지명이 남아 있는데, 궁리는 세종의 다섯째 아들인 광평대군과 그의 아들 영순군이 거주하여서, 원리는 정조의 후궁 원빈 홍씨의 묘소인 인명원이 있어서 유래되었다. 안암동의 서쪽은 성북천과 맞닿아 있는데, 과거에는 이를 안암천 또는 안감내 등으로 불렀다고 전해진다.'
  },
  {
    id:8,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FmGQSJ%2Fbtq04LarH5J%2FFvzbtzrftuqrz4dAOK6UnK%2Fimg.png',
    tab: '월곡동',
    name: '천장산의 형세가 마치 반달과 같다고 하여 유래된 월곡동',
    content: '월곡동은 현재 하월곡동에 있는 천장산의 형세가 마치 반달과 같다고 하여 그 산 근방에 있는 마을을 다릿골 혹은 다릿굴이라 부르는 데서 유래했다. 이 중 위쪽을 ‘웃다릿골‧웃다릿굴’, 아래쪽을 ‘아래다릿골‧아래다릿굴’이라 했는데, 이것이 지금의 상월곡동과 하월곡동이 되었다고 한다. 다른 유래도 전해져 내려온다. 조선시대 이 지역에는 주막이 밀집해 있었는데 지방에서 올라온 소 장수들이 달밤에 이곳에 도착하여 숙박을 하고 달이 채 지지 않은 새벽 무렵 일어나 소의 가격을 흥정했기에 ‘월곡’이라 불렸다는 것이다. 어떤 이야기가 사실이든 이 지역은 도성에서 원산으로 가는 길목에 위치한 교통의 요지임은 틀림없다.'
  },
  {
    id:9,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FchNSES%2Fbtq01p0xsVU%2FUMKoK1VxBKB9GiTNKNFPi1%2Fimg.png',
    tab: '장위동',
    name: '마을 뒤 장위산이라 불리는 산에서 유래된 장위동',
    content: '장위동은 마을 뒤 장위산(獐位山)의 이름에서 유래되었다는 설과 오래전 명신(名臣), 즉 ‘높은 지위’의 사람이 살았던 것에서 장위(長位)라는 이름이 유래되었다는 설이 있다. 예부터 장위동 사람들 사이에는 ‘양주밥 먹고 고양굿 한다’는 말이 있었는데 이는 농지는 양주에 두고 고양(장위동)에서 거주한다는 뜻이다. 장위동에는 민속문화재 제25호 장위동 김진흥 가옥이 있는데, 순조의 셋째 딸 덕온공주와   부마 윤의선, 그의 아들 윤용구가 살았던 곳이다. ‘부마가옥’이라고도 불렀다. 장위동 땅의 대부분이 윤용구와 그 집안의 소유였으나, 1960년경부터 팔기 시작하였고, 이후 동방생명보험회사에서 택지를 조성하여 팔기 시작하며 주택단지가 들어섰다.'
  },
  {
    id:10,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FBwn2p%2Fbtq1z4Hjqpp%2FPZlq0Fftq1SZEmrPicCkHk%2Fimg.png',
    tab: '정릉동',
    name: '왕후의 무덤을 품고 있는 데서 유래된 정릉동',
    content: '조선 태조의 계비 신덕왕후 강씨의 능인 정릉(貞陵)이 있는 데서 동명이 유래했다. 정릉동의 옛 지명은 ‘살한이’로, 그것을 한자화한 사을한리(沙乙閑里)·사아리(沙阿里)로 불리기도 했다. 이후 정릉이 옮겨 오면서 ‘능이 있는 마을’이라는 의미로 ‘능말’ 혹은 ‘능동’으로 불리기도 하였다. 정릉동의 면적은 성북구 소재 동 중에서 가장 넓으나 북한산 남쪽 산비탈 일대가 포함되어 있어 주거가 가능한 면적은 그리 넓지 않다. 오늘날까지 전해져오는 마을 이름이 많은 편인데, 정릉 부근은 능말, 북한산 아래 물이 맑은 마을은 청수동, 고려 때 귀양 온 손씨가 모여 살았다는 손가정, 문설주와 닮은 바위가 있는 문바위골, 배 모양의 바위가 있는 배바위골 등이 존재한다.'
  },
  {
    id:11,
    img: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbr6j0h%2Fbtq038wU1gu%2FXuv7h4Bz93YBxuVeBgzrHk%2Fimg.png',
    tab: '종암동',
    name: '북처럼 생긴 커다란 바위가 있는 마을에서 유래된 종암동',
    content: '종암동의 이름은 고려대학교 뒷산에 북처럼 생긴 커다란 바위, 즉 북바위가 있었기에 이를 한자로 ‘종암(鐘岩)’ 또는 ‘고암(鼓岩)’이라고 부른 데서 유래했다. 한편 모랫말 또는 모랫골이라고 불리기도 하였는데 이는 월곡동과 경계가 되는 정릉천의 상류에서 내려온 모래가 쌓여 언제나 모래밭이었기 때문이라고 전해진다. 이 모래는 소금기가 전혀 없고 불순물이 섞이지 않은 양질의 모래여서 하수관이나 전봇대 등을 만드는데 쓰였다고 한다.'
  }
];


const useTabs = (initialTabs, allTabs) => {
  const [contentIndex, setContentIndex] = useState(initialTabs);
  return {
    contentItem: allTabs[contentIndex],
    contentChange: setContentIndex
  };
};

export default function OriginName() {
const { contentItem, contentChange } = useTabs(0, content);

const [windowSize, setWindowSize] = useState(window.innerWidth);

const handleResize = () => {
  setWindowSize(window.innerWidth);
};

useEffect(() => {
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);
  
return (
    <div id='OriginName' className='static container titleLayout'>
      <span>찾으시는 지역을 선택하세요</span>
      <div className='BtnWrapper'>
      {content.map((section, index) => (
        <Button
        type="button"
        key={index}
        onClick={() => contentChange(index)}
        >
          {section.tab}
        </Button>
      ))}
      </div>
      <sub style={{color: "#828282", float: "right", margin: '20px'}}>● 정렬 : 가나다 순</sub>
      <div>
      {windowSize > 425 ? ( // 사이즈 조절 가능
        <img src={contentItem.img} alt='성북구 지도' className='MapImg abs' />
      ) : (
        <img src={MobileImg} alt="성북구 지도" className='MapImg abs' />
      )}
      </div>
      <div className='ContentWrapper'>
        <h3 className='ContentTitle'>{contentItem.name}</h3>
        <p>{contentItem.content}</p>
        <p>{contentItem.none}</p>
      </div>
      <div className='ContentWrapper'>
      { contentItem.id === 100 ? content.map((section, index) => (
            <div key={index}>
            <h3 className='ContentTitle'>{section.name}</h3><p>{section.content}</p>
            </div>
          )) : undefined}
      </div>
      <div className='KeywordOnmapWrapper onlyPC'>
        <div style={{width:'680px', margin:'0 auto'}}>
          {content.map((section, index) => (
            <Button className='KeywordOnmap' key={index} onClick={() => contentChange(index)}>{section.tab}</Button>
          ))
          }
        </div>
      </div>
    </div>
  );
}
